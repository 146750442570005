import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from 'react-router-dom';

import { ThemeProvider, createTheme } from '@mui/material';

import Header from './components/Header';
import ProtectedRoute from './components/ProtectedRoute';
import AuthPage from './pages/AuthPage';
import CartPage from './pages/CartPage';
import CustomerInfoPage from './pages/CustomerInfo';
import Home from './pages/Home';
import OTPLoginPage from './pages/OTPLoginPage';
import OrderDetail from './pages/OrderDetail';
import Orders from './pages/Orders';
import Payment from './pages/Payment';
import ProfilePage from './pages/ProfilePage';
import PromotionsPage from './pages/PromotionsPage';
import RestaurantPage from './pages/RestaurantPage';
import TrackOrder from './pages/TrackOrder';
import { emptyCart } from './store/reducers/CartReducer';
import { logout, setLocation } from './store/reducers/UserReducer';

const versionKey = 'APP_VERSION';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    white: {
      main: '#fff',
    },
  },
});

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const appRouter = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <Outlet />
        <ScrollToTop />
      </>
    ),
    errorElement: (
      <>
        <Header />
      </>
    ),
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/auth',
        element: <AuthPage />,
      },
      {
        path: '/login',
        element: <OTPLoginPage />,
      },
      {
        path: '/restaurants/:slug',
        element: <RestaurantPage />,
      },
      {
        path: '/cart',
        element: <CartPage />,
      },
      {
        path: '/customerInfo',
        element: <ProtectedRoute children={<CustomerInfoPage />} />,
      },
      {
        path: '/payment',
        element: <ProtectedRoute children={<Payment />} />,
      },
      {
        path: '/orders',
        element: <ProtectedRoute children={<Orders />} />,
      },
      {
        path: '/order/:id',
        element: <ProtectedRoute children={<OrderDetail />} />,
      },
      {
        path: '/order/:id/track',
        element: <ProtectedRoute children={<TrackOrder />} />,
      },
      {
        path: '/profile',
        element: <ProtectedRoute children={<ProfilePage />} />,
      },
      {
        path: '/promotions',
        element: <ProtectedRoute children={<PromotionsPage />} />
      }
    ],
  },
]);

const App = () => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (err) => {
          setError(err.message);
        },
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  }, []);

  const [storedVersion, setStoredVersion] = useState(null);
  const [currentVersion, setCurrentVersion] = useState('3.1');
  const user_storage = {"isLoading":"false","isLoggedIn":"false","accessToken":"","token":"null","refreshToken":"null","user":"null","location":"null","open":"false","_persist":"{\"version\":1,\"rehydrated\":true}"}
  const cart_storage = {"customer":"","discount":"0","firstname":"","lastname":"","numOfGuests":"0","order":{},"orderType":"IN_STORE","phoneNumber":"","products":[],"salesTax":"0","serviceCharges":"0","store":null,"subTotal":"0","table":null,"taxRate":"0","total":"0","waiter":{},"_persist":{"version":1,"rehydrated":true}}
  useEffect(() => {
    const storedValue = localStorage.getItem(versionKey);
    setStoredVersion(storedValue);
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    if (!storedVersion) {
      // Version not stored, set it
      localStorage.setItem(versionKey, currentVersion);
      localStorage.setItem("loyaltydish-customer-ui.user", user_storage);
      localStorage.setItem("loyaltydish-customer-ui.cart", cart_storage);
    } else if (storedVersion !== currentVersion) {
      // Version changed, inform user and clear storage
      alert('New version deployed. Local storage has been cleared.');
      localStorage.setItem("loyaltydish-customer-ui.user", user_storage);
      localStorage.setItem("loyaltydish-customer-ui.user", cart_storage);
      localStorage.clear();
      dispatch(logout())
      dispatch(emptyCart())
      localStorage.removeItem("loyaltydish-customer-ui.user")
      localStorage.removeItem("loyaltydish-customer-ui.cart")
      setStoredVersion(currentVersion); // Update stored version state
    }
  }, [storedVersion, currentVersion]); // Re-run on changes to storedVersion or currentVersion


  useEffect(() => {
    if (latitude !== null && longitude !== null) {
      fetch(
        `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`,
      )
        .then((response) => response.json())
        .then((data) => {
          dispatch(setLocation(data.address));
        })
        .catch((error) => {
          setError('Error fetching data.');
        });
    }
  }, [latitude, longitude]);

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={appRouter}></RouterProvider>
    </ThemeProvider>
  );
};
export default App;
