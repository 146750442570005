import { gql } from '@apollo/client';

const GET_STRIPE_PAYMENT_INTENT = gql`
  mutation getIntent($orderId: ID!) {
    stripeCreatePaymentIntent(input: { orderId: $orderId }) {
      success
      errors
      clientSecret
    }
  }
`;

export { GET_STRIPE_PAYMENT_INTENT };

