import React from 'react';

import CloseIcon from '@mui/icons-material/Close';

import Mic from '../assets/icons/mic.png';
import '../assets/stylesheets/searchBar.scss';

export default function SearchBar({ value, onChange, onClose, mic }) {
  return (
    <div className="searchBar">
      <input
        type="text"
        placeholder="Search"
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
      {mic && <img src={Mic} />}
      <CloseIcon
        onClick={() => {
          onChange('');
          onClose();
        }}
      />
    </div>
  );
}
