import { useQuery } from '@apollo/client';

import React, { useEffect, useMemo, useState } from 'react';
// import SquareForm from './paymentGatewaysForms/square';
// import StripeForm from './paymentGatewaysForms/stripe';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Header from '../components/Header';
import SpinLoader from '../components/SpinLoader';
import CashOnDelivery from '../components/paymentGatewaysForms/cod';
import RazorpayForm from '../components/paymentGatewaysForms/razorpay';
import StripeForm from '../components/paymentGatewaysForms/stripe';
import VenmoForm from '../components/paymentGatewaysForms/venmo';
import { emptyCart } from '../store/reducers/CartReducer';
import {
  GET_PAYMENTGATEWAY,
  useCompleteOrderMutation,
  useUpdateOrderStatusMutation,
} from '../store/services/OrderApi';

const paymentGatewaysForms = {
  stripe: StripeForm,
  //   square: SquareForm,
  razorpay: RazorpayForm,
  venmo: VenmoForm,
  cod: CashOnDelivery,
};

export default function Payment() {
  const cart = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [localCart, setLocalCart] = useState(cart); // Initialize with global cart state
  useEffect(() => {
    setLocalCart(cart); // Update local state whenever global cart state changes
  }, [cart]);

  // API calls
  const { loading, error, data } = useQuery(GET_PAYMENTGATEWAY, {
    variables: {
      storeId: localCart?.store?.pk,
    },
  });
  const [updateOrderStatus, { isSuccess: orderSuccess }] =
    useUpdateOrderStatusMutation();
  const [completeOrder, { isSuccess: completeOrderSuccess }] =
    useCompleteOrderMutation();
  const [res, setRes] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  // Internal State

  const paymentGateways = useMemo(() => {
    if (!data) return null;

    // Assuming you want to transform the edges into a new structure or simply use them as is
    const transformedEdges = data.paymentGatewaysSettings.edges.map(
      (gateway) => {
        // Example transformation: Return a new object with desired properties
        return {
          id: gateway.node.gateway.id,
          pk: gateway.node.gateway.pk,
          name: gateway.node.gateway.name,
          settings: gateway.node.settings,
        };
      },
    );

    // Uncomment and adjust this part if you need to parse settings
    // if (data.paymentGatewaysSettings && data.paymentGatewaysSettings.settings) {
    //   const parsedSettings = JSON.parse(data.paymentGatewaysSettings.settings);
    //   // Transform or merge parsedSettings as needed
    // }

    return transformedEdges;
  }, [data]);

  useEffect(() => {
    if (res) {
      onPayment(res);
    }
  }, [res]);

  const onPayment = async (res) => {
    const variables = {
      id: cart.order.pk,
      paymentType: 'CREDIT_CARD',
      cashReceived: cart.total,
      change: parseFloat(0),
      paymentGatewayId: cart.activePaymentGateway,
      paymentGatewayParams: JSON.stringify({
        payment_id: res?.razorpay_payment_id,
      }),
    };
    const orderData = await completeOrder(variables);
    if(!orderData.data.checkoutOrder.success) {
      setIsLoading(false);
      return;
    }
    dispatch(emptyCart());
    await updateOrderStatus({
      orderId: cart.order.pk,
      status: 'PLACED',
    });
    setIsLoading(false);
    navigate(`/order/${cart.order.orderId}/track`, { replace: true });
  };

  return (
    <div style={{ background: 'rgba(162, 158, 180, 0.10)', height: '100vh' }}>
      {isLoading ? (
        <SpinLoader />
      ) : (
        <>
          <Header
            title="Payment"
            withBackBtn
            // backBtnNavigateTo="/customerInfo"
          />
          <div
            className="cart-container"
            style={{ marginBottom: 70, maxWidth: '800px', margin: 'auto' }}
          >
            <p className="title">Recommended</p>
            <>
              {paymentGateways &&
                paymentGateways?.map((gateway) => {
                  const Form = paymentGatewaysForms[gateway.name];
                  return (
                    <Form
                      key={gateway.pk}
                      cart={cart}
                      gateway={gateway}
                      settings={gateway.settings}
                      onLoading={setIsLoading}
                      onPaymentGatewayParamsResolved={(res) => {
                        setRes(res);
                      }}
                    />
                  );
                })}
            </>
          </div>
        </>
      )}
    </div>
  );
}

  // const Form = useMemo(() => {
  //   if (paymentGatewaySettings) {
  //     return paymentGatewaysForms[paymentGatewaySettings.gateway.name];
  //     // return paymentGatewaysForms['stripe'];
  //   }
  // }, [paymentGatewaySettings]);

  // useEffect(() => {
  //   if (completeOrderSuccess) {
  //     navigate(`/order/${cart.order.orderId}/track`, { replace: true });
  //       dispatch(emptyCart());
  //   }
  // }, [completeOrderSuccess]);