import React, { useEffect, useState } from 'react';

import '../assets/stylesheets/home.scss';
import Header from '../components/Header';
import PickupDeliverySwitch from '../components/PickupDeliverySwitch';
import StoreCard from '../components/StoreCard';
import { useGetStoresQuery } from '../store/services/StoreApi';

export default function Home() {
  // Debounded Search state
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  useEffect(() => {
    const debounce = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => clearTimeout(debounce);
  }, [searchTerm]);

  const { data, isSuccess } = useGetStoresQuery({ name: debouncedSearchTerm });

  return (
    <div>
      <Header
        withDrawer
        withLogo
        withSearch
        withCartBtn
        onSearchChange={(text) => {
          setSearchTerm(text);
        }}
        searchValue={searchTerm}
      />

      <div style={{ maxWidth: '800px', width: "100%", margin: "auto"}}>
        <PickupDeliverySwitch />

        <div className="home-item-container">
          {isSuccess && data.map((store, idx) => <StoreCard key={store.id} {...store} />)}
        </div>
      </div> 
    </div>
  );
}
