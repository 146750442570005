import React from 'react';
import Sheet from 'react-modal-sheet';
import { useSelector } from 'react-redux';

import { Box, Button } from '@mui/material';

import '../assets/stylesheets/promotions.scss';
import { formatDate, getCurrencySymbol } from '../helpers/utils';

const PromotionsModal = ({ item, isOpen, setIsOpen }) => {
  const currency = useSelector((state) => state.store.currency);
  const pictures = item?.pictures?.edges.map((edge) => edge.node.url) || [];

  
  const renderOfferValue = () => {
    if (item.offerType === 'PERCENTAGE') {
      return `${item.offerValue}%`;
    }
    return `${ getCurrencySymbol(currency) } ${item.offerValue}`;
  };

  return (
    <Sheet isOpen={isOpen} onClose={() => setIsOpen(false)} snapPoints={[]}>
      <Sheet.Container className="modal-container">
        <Sheet.Content>
          <Box className="modal-header">
            <h2>{item.name}</h2>
          </Box>
          <Box className="info-box">
            <h2>Expiration:</h2>
            {item.start && item.end ? (
              <p>
                Offer valid from {formatDate(item.start)} to{' '}
                {formatDate(item.end)}
              </p>
            ) : (
              <p>N/A</p>
            )}
          </Box>
          <Box className="info-box">
            <h2>Details:</h2>
            <p>
              {/* { (item.start && item.end) ? `Offer valid from ${formatDate(item.start)} to ${formatDate(item.end)}`: ""} */}
              {item.minimumSpend ? `Minimum spend: ${item.minimumSpend}` : ''}
            </p>
            <p>
              {item.minimumSpendAmount
                ? `Minimum spend amount: ${item.minimumSpendAmount}`
                : ''}
            </p>
            <p>Offer Value: {renderOfferValue()}</p>
          </Box>
          <Box className="info-box">
            <h2>Images:</h2>
            {pictures.length > 0 ? (
              pictures.map((url, index) => (
                <img key={index} src={url} alt="Offer" />
              ))
            ) : (
              <p>No images available</p>
            )}
          </Box>
          <Button
            variant="outlined"
            sx={{ mx: 2, mt: 4 }}
            onClick={() => setIsOpen(false)}
          >
            Close
          </Button>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

export default PromotionsModal;
