import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import React from 'react';

const VenmoForm = ({
  settings,
  gateway,
  onPaymentGatewayParamsResolved,
  cart,
  onLoading,
}) => {
  return (
    <div
      style={{
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        height: 50,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: 10,
        paddingLeft: 10,
        margin: '5px 15px 5px 15px',
        cursor: 'pointer',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img alt='' src='https://images.ctfassets.net/gkyt4bl1j2fs/ym6BkLqyGjMBmiCwtM7AW/829bf561ea771c00839b484cb8edeebb/App_Icon.png?w=276&h=276&q=50&fm=png&bg=transparent' width={24} />
        <p style={{ fontFamily: 'Poppins', marginLeft: 10, fontSize: 16 }}>
          Pay Using Venmo
        </p>
      </div>
      <KeyboardArrowRight />
    </div>
  );
};

export default VenmoForm;
