import { useMutation } from '@apollo/client';

import React from 'react';
import { useSelector } from 'react-redux';

import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Alert, Box, Button } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import '../assets/stylesheets/promotions.scss';
import { getCurrencySymbol } from '../helpers/utils';
import { ADD_OFFER_MUTATION, REMOVE_OFFER_MUTATION } from '../store/services/OffersApi';

const PromotionItem = ({ item, setIsOpen, setItem, activeId, activeCode, setActiveId, setActiveCode }) => {
  const cart = useSelector((state) => state.cart);
  const currency = useSelector((state) => state.store.currency);
  const navigate = useNavigate()

  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [alertOpen, setAlertOpen] = React.useState(false);

  const openModal = () => {
    setIsOpen(true);
    setItem(item);
  };

  const renderOfferValue = () => {
    if (item.offerType === 'PERCENTAGE') {
      return `${item.offerValue}%`;
    }
    return `${getCurrencySymbol(currency)} ${item.offerValue}`;
  };

  const [addOffer] = useMutation(ADD_OFFER_MUTATION);

  const applyPromotion = async () => {
    console.log('variables', {
      orderId: cart.order.id,
      discountCode: item.code,
    });
    const response = await addOffer({
      variables: { orderId: cart.order.pk, discountCode: item.code },
    });
    if (response.data.createOrderDiscount.errors) {
      setError(
        response.data.createOrderDiscount.errors.nonFieldErrors[0].message,
      );
    } else {
      setSuccess('Promotion applied successfully');
      setActiveCode(item.code);
      navigate('/cart')
    }
    setAlertOpen(true);
  };

  const [removeOffer] = useMutation(REMOVE_OFFER_MUTATION);

  const removePromotion = async () => {
    const response = await removeOffer({ variables: { id: activeId } });
    setError(null)
    setSuccess(null)
    if (response.data.deleteOrderDiscount.errors) {
      setError(
        response.data.deleteOrderDiscount.errors.nonFieldErrors[0].message,
      );
    } else {
      setSuccess('Promotion removed successfully');
      setActiveId(null);
      setActiveCode(null);
    }
    setAlertOpen(true);
  };

  return (
    <Box className="promotion-item">
      {error && alertOpen && (
        <Alert
          severity="error"
          variant="outlined"
          onClose={() => setAlertOpen(false)}
        >
          {error}
        </Alert>
      )}
      {success && alertOpen && (
        <Alert
          severity="success"
          variant="outlined"
          onClose={() => setAlertOpen(false)}
        >
          {success}
        </Alert>
      )}
      <h2>
        <LocalOfferIcon />
        {item.name}
      </h2>
      <p className="sub-title">
        {/* { (item.start && item.end) ? `Offer valid from ${formatDate(item.start)} to ${formatDate(item.end)}`: ""} */}
        {item.minimumSpend ? `Minimum spend: ${item.minimumSpend}` : ''}
      </p>
      <p className="sub-title">
        {item.minimumSpendAmount
          ? `Minimum spend amount: ${item.minimumSpendAmount}`
          : ''}
      </p>
      <p className="sub-title">Offer Value: {renderOfferValue()}</p>
      <Box className="button-group">
        <Button
          variant="contained"
          className="primary"
          onClick={ () => item.code===activeCode ? removePromotion() : applyPromotion()}
        >
          {
            (activeCode===item.code) ? 'Applied' : 'Apply'
          }
        </Button>
        <Button variant="outlined" className="secondary" onClick={openModal}>
          Know More
        </Button>
      </Box>
    </Box>
  );
};

export default PromotionItem;
