import { useQuery } from '@apollo/client';

import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { useLocation } from 'react-router-dom';
import Header from '../components/Header';
import MyLoader from '../components/Loader';
import PromotionItem from '../components/PromotionItem';
import PromotionsModal from '../components/PromotionsModal';
import { GET_OFFERS_QUERY } from '../store/services/OffersApi';

const PromotionsPage =  () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const store = useSelector((state) => state.store);

  const location = useLocation()
  const [discountId, setDiscountId] = useState(location.state?.discountId);
  const [discountCode, setDiscountCode] = useState(location.state?.discountCode);

  const [item, setItem] = React.useState({
    id: 0,
    name: 'Promotion Name',
    minimumSpend: 0,
    minimumSpendAmount: 0,
    offerValue: 0,
    offerType: 'PERCENTAGE',
    code: '',
  });

  const response = useQuery(GET_OFFERS_QUERY, {
    variables: { stores_Slug: store.slug },
  });
  
  if (response.loading) {
    return <MyLoader /> // Or any loading indicator
  }
  
  if (response.error) {
    return <div>Error: {response.error.message}</div>; // Display the error message
  }

  const promotions = response.data.offers.edges.map((edge) => edge.node) || [];

  return (
    <>
      <Header withBackBtn title={'Promotions'} />
      <Box sx={{ px: 3, maxWidth: '800px', mx: 'auto' }}>
        <h2 style={{ fontWeight: 500 }}>Available Promotions</h2>
        <Box>
          {promotions.map((promotion) => (
            <PromotionItem
              key={promotion.code}
              item={promotion}
              setIsOpen={setIsOpen}
              setItem={setItem}
              activeId={discountId}
              activeCode={discountCode}
              setActiveId={setDiscountId}
              setActiveCode={setDiscountCode}
            />
          ))}
        </Box>
        <PromotionsModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          item={item || promotions[0]}
        />
      </Box>
    </>
  );
  
};

export default PromotionsPage;
