import React, { useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import Counter from './Counter';
import useRipple from './hooks/useRipple';

export default function ItemCard(props) {
  const {
    isExist,
    onAddPress,
    onAddQuantity,
    onReduceQuantity,
    handleClickOpen,
    checkItem,
    item,
  } = props;
  const {
    name,
    // description,
    shortDescription,
    pictures,
    price,
    productComponentsGroups,
    quantities,
  } = item;
  let extra = productComponentsGroups.edges.length ? true : false;

  const [direction, setDirection] = useState(1);

  const ref = useRef(null);
  const itemRef = useRef(null);
  //pass the ref to the useRipple hook
  const ripples = useRipple(ref);
  const itemRipples = useRipple(ref);

  const _onAddPress = () => {
    if (extra) {
      handleClickOpen(item);
    } else {
      setDirection(1);
      onAddPress();
    }
  };

  const _onAddQuantity = () => {
    setDirection(1);
    onAddQuantity();
  };

  const _onReduceQuantity = () => {
    setDirection(-1);
    onReduceQuantity();
  };


  return (
    <div ref={itemRef} className={`item-container ${checkItem(item) || "disable-item"}`} >
      {itemRipples}
      <div onClick={() => handleClickOpen(item)} className="first">
        <div className="title">{name}</div>
        <div className="price">{price}</div>
        <div className="description">{shortDescription}</div>
      </div>
      <div className="second">
        <div className="image">
          {pictures.edges.length > 0 && pictures.edges[0].node.url && (
            <img
              src={pictures.edges[0].node.url}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              alt="Product"
            />
          )}
        </div>

        <div ref={ref} className="add-button-container">
          {ripples}
          <div className="first">
            <div className="second">
              <button
                onClick={_onReduceQuantity}
                className={`btn minus ${isExist && 'act'}`}
              >
                <RemoveIcon className="btn-txt" />
              </button>
              <Counter
                onAddPress={_onAddPress}
                isExist={isExist}
                dir={direction}
              />
              <button
                onClick={_onAddQuantity}
                className={`btn plus ${isExist && 'act'}`}
              >
                <AddIcon className="btn-txt" />
              </button>
            </div>
            {extra && <div className="cust-button">Customisable</div>}
          </div>
        </div>
      </div>
    </div>
  );
}
