import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import ShoppingCart from '../assets/icons/shopping-cart.svg';
import { getTotal } from '../helpers/utils';
import useRipple from './hooks/useRipple';

export default function PlaceOrderButton(props) {
  const ref = useRef(null);
  const ripples = useRipple(ref);
  const cart = useSelector((state) => state.cart);

  if (cart.products.length) {
    return (
      <div ref={ref} onClick={props.onPress} className="placeorder-button">
        {ripples}
        <div className="first">
          <img src={ShoppingCart} alt='' />
          <p>{cart.products.length}</p>
        </div>
        <div className="second">Place order</div>
        <div className="third">
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: cart.store.currency,
          }).format(cart.total || getTotal(cart.products).total )}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
