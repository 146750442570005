import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

import { store } from '../';

const API_ENDPOINT = process.env.REACT_APP_GRAPHQL_API_URL;

const getToken = async () => {
  const userData = store.getState().auth;
  return userData.token;
};

const authLink = setContext(async (req, { headers }) => ({
  headers: {
    ...headers,
    authorization: `Bearer ${await getToken()}`,
    'X-Secret-Key': '3SIQkeOY.TuwPP9bZ7F9ItHZX995sKWg9faHfWUtN',
    'Accept-Encoding': 'gzip',
  },
}));

const httpLink = new HttpLink({
  uri: API_ENDPOINT,
  fetchOptions: {
    responseType: 'json',
  },
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_GRAPHQL_API_WS_URL,
  options: {
    reconnect: true,
  },
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

export default client;
