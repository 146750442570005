import * as React from 'react';

import AcceptIcon from '@mui/icons-material/Check';
import DeliveryIcon from '@mui/icons-material/DeliveryDining';
import FastFoodIcon from '@mui/icons-material/LunchDining';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const steps = {
  PLACED: {
    label: 'Order Placed',
    description: `10:20pm, 12th July 2021`,
    icon: <ReceiptLongIcon />,
  },
  ACCEPTED: {
    label: 'Accepted',
    icon: <AcceptIcon />,
  },
  IN_PREPARATION: {
    label: 'Your order is being prepared...',
    icon: <DeliveryIcon />,
  },
  READY: {
    label: 'Ready!',
    icon: <FastFoodIcon />,
  },
  DELIVERED: {
    label: 'Completed',
    icon: <DeliveryIcon />,
  },
};

export default function VerticalLinearStepper({ activeStep, order }) {
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepLabelClasses.label}`]: {
      color: 'red',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#0D0E10',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#0D0E10',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#eaeaf0',
      borderLeftWidth: 3,
      borderRadius: 1,
      minHeight: 40,
      borderLeftStyle: 'dashed',
      marginLeft: 12,
    },
  }));
  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, #FF6B15 0%, #FF6B15 50%, #FF6B15 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, #0D0E10 0%, #0D0E10 50%, #0D0E10 100%)',
    }),
  }));
  function QontoStepIcon(step) {
    return (props) => {
      const { active, completed, className } = props;

      return (
        <ColorlibStepIconRoot
          ownerState={{ completed, active }}
          className={className}
        >
          {steps[step].icon}
        </ColorlibStepIconRoot>
      );
    };
  }

  function getTimestamp(step) {
    return '';
  }

  return (
    <Box>
      <Stepper
        activeStep={Object.keys(steps).indexOf(activeStep)}
        orientation="vertical"
        connector={<QontoConnector />}
      >
        {Object.keys(steps).map((step, idx) => (
          <Step key={idx}>
            <StepLabel
              style={{ alignItems: 'center' }}
              optional={
                <Typography variant="caption">{getTimestamp(step)}</Typography>
              }
              StepIconComponent={QontoStepIcon(step)}
            >
              {steps[step].label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
