import dayjs from 'dayjs';
import * as Yup from 'yup';

import { useEffect, useMemo, useRef, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AccessTime, MyLocation } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  ButtonGroup,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
// import { TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import deliveryIcon from '../assets/icons/delivery.svg';
import map from '../assets/icons/map.svg';
import Header from '../components/Header';
import PhoneNumberInput from '../components/PhoneNumberInput';
import SpinLoader from '../components/SpinLoader';
import { formatAddress, getTotal } from '../helpers/utils';
import {
  setCustomerInfo,
  setOrder,
  setOrderType,
} from '../store/reducers/CartReducer';
import { setLocation } from '../store/reducers/UserReducer';
import { useCreateOrderMutation } from '../store/services/OrderApi';

const schema = Yup.object({
  firstname: Yup.string().required('First name is required'),
  phoneNumber: Yup.string()
    .required()
    .test(
      'is-phone-number',
      (d) => `Invalid phone number`,
      (value) => isValidPhoneNumber(value),
    ),
});

const showMap = (isDelivery, user_location, store_location) => {
  //isDelivery ? (user_location && formatAddress(user_location)) : store_location
  // isDelivery && !user_location 
  if (isDelivery) {
    if (user_location) {
      if (user_location.addressLine1 !== undefined) {
        return `${user_location.addressLine1}, ${user_location.addressLine2 ? user_location.addressLine2 + ',' : ''} ${user_location.city}, ${user_location.state}, ${user_location.country}, ${user_location.zip || user_location.postcode || ''}`;
      }
      return formatAddress(user_location);
    } else {
      return "No Address Added. Click on 'Change' to add address.";
    }
  } else {
    if (store_location) {
      return store_location;
    } else {
      return "No Address Added. Click on 'Change' to add address.";
    }
  }
};

const AddressBox = ({
  orderType,
  user_location,
  store_location,
  customLocation,
  setCustomLocation,
  setGlobalError,
}) => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  // const [location, setLocation_] = useState();
  const addressLine1Ref = useRef();
  const addressLine2Ref = useRef();
  const countryRef = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const zipCodeRef = useRef();
  const defaultDeliveryAddressRef = useRef();

  const [addressLineError, setAddressLineError] = useState(null);
  const [addressLine2Error, _] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [stateError, setStateError] = useState(null);
  const [countryError, setCountryError] = useState(null);
  const [zipCodeError, setZipCodeError] = useState(null);

  const dispatch = useDispatch();

  const isDelivery = !(
    orderType === 'IN_STORE' || orderType === 'ONLINE_PICKUP'
  );

  useEffect(()=> {
    setIsOpen(isDelivery && user_location?.addressLine1 === undefined)
  }, [isDelivery, user_location])

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          setError(null);
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          const temp_latitude = position.coords.latitude;
          const temp_longitude = position.coords.longitude;
          if (temp_latitude !== null && temp_longitude !== null) {
            fetch(
              `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${temp_latitude}&lon=${temp_longitude}`,
            )
              .then((response) => response.json())
              .then((data) => {
                if (
                  data.address.country === 'United States' ||
                  data.address.country === 'India' ||
                  data.address.country === 'New Zealand'
                ) {
                  dispatch(setCustomerInfo({ location: data.address }));
                  dispatch(setLocation(data.address));
                  setCustomLocation(data.address);
                } else {
                  setGlobalError(
                    'Location not supported. Only United States, India and New Zealand are supported currently.',
                  );
                }
              })
              .catch((error) => {
                setError('Error fetching data.');
              });
          }
          setIsOpen(false);
        },
        (err) => {
          setError(err.message);
        },
      );
    } else {
      setGlobalError('Geolocation is not supported by this browser.');
    }
  }

  const save = async () => {
    const addressLine1 = addressLine1Ref.current.children[1].children[0].value;
    const addressLine2 = addressLine2Ref.current.children[1].children[0].value;
    const country =
      countryRef.current.children[0].children[1].children[0].value;
    const city = cityRef.current.children[1].children[0].value;
    const state = stateRef.current.children[1].children[0].value;
    const defaultDeliveryAddress =
      defaultDeliveryAddressRef.current.children[0].checked;
    const zipCode = zipCodeRef.current.children[1].children[0].value;

    if (addressLine1 === '') {
      setAddressLineError('Address Line 1 is required');
      return;
    }
    if (city === '') {
      setCityError('City is required');
      return;
    }
    if (state === '') {
      setStateError('State is required');
      return;
    }
    if (country === '') {
      setCountryError('Country is required');
      return;
    }
    if (zipCode === '') {
      setZipCodeError('Zip Code is required');
      return;
    }
    if (
      addressLine1 === '' ||
      city === '' ||
      state === '' ||
      country === '' ||
      zipCode === ''
    )
      return;

    const locationData = {
      addressLine1,
      addressLine2: addressLine2 || '',
      city,
      state,
      country,
      zip: zipCode,
      deliveryLocation: {
        latitude,
        longitude,
      },
    };

    // const location = `${addressLine1}, ${addressLine2}, ${city}, ${state}, ${country}`;
    dispatch(setCustomerInfo({ location: locationData }));
    if (defaultDeliveryAddress) {
      dispatch(setLocation(locationData));
    }
    setCustomLocation(locationData);
    setIsOpen(false);
  };

  const countries = [
    { label: 'United States' },
    { label: 'India' },
    { label: 'New Zealand' },
  ];

  const LocationModal = () => {
    return (
      <Sheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Sheet.Container>
          <Sheet.Content>
            <Box sx={{ padding: 2, paddingBottom: 10 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6">Add Address</Typography>
                <ButtonGroup>
                  <Button
                    onClick={getLocation}
                    sx={{
                      borderColor: '#FF6B15',
                      color: '#FF6B15',
                      borderRadius: '20px',
                      borderWidth: '2px',
                      paddingBlock: '1px',
                      textTransform: 'capitalize',
                    }}
                  >
                    <MyLocation sx={{ marginRight: 1 }} />
                    Use Current Location
                  </Button>
                </ButtonGroup>
              </Box>
              <TextField
                label="Address Line 1"
                variant="outlined"
                fullWidth
                ref={addressLine1Ref}
                sx={{ marginTop: 2, marginBottom: 1 }}
                defaultValue={user_location?.addressLine1 || ''}
                required
              />
              {addressLineError && (
                <Typography
                  color="error"
                  sx={{ marginBottom: 1, marginTop: -1, textAlign: 'left' }}
                >
                  {addressLineError}
                </Typography>
              )}
              <TextField
                label="Address Line 2"
                variant="outlined"
                fullWidth
                sx={{ marginBottom: 1 }}
                ref={addressLine2Ref}
                defaultValue={user_location?.addressLine2 || ''}
              />
              {addressLine2Error && (
                <Typography
                  color="error"
                  sx={{ marginBottom: 1, marginTop: -1, textAlign: 'left' }}
                >
                  {addressLine2Error}
                </Typography>
              )}
              <TextField
                label="Zip Code"
                variant="outlined"
                fullWidth
                sx={{ marginBottom: 1 }}
                defaultValue={user_location?.zip || ''}
                ref={zipCodeRef}
                required
                type="number"
              />
              {zipCodeError && (
                <Typography
                  color="error"
                  sx={{ marginBottom: 1, marginTop: -1, textAlign: 'left' }}
                >
                  {zipCodeError}
                </Typography>
              )}
              <TextField
                label="City"
                variant="outlined"
                fullWidth
                ref={cityRef}
                required
                sx={{ marginBottom: 1 }}
                defaultValue={user_location?.city || ''}
              />
              {cityError && (
                <Typography
                  color="error"
                  sx={{ marginBottom: 2, marginTop: -1, textAlign: 'left' }}
                >
                  {cityError}
                </Typography>
              )}
              <TextField
                label="State"
                variant="outlined"
                fullWidth
                ref={stateRef}
                sx={{ marginBottom: 1 }}
                required
                defaultValue={user_location?.state || ''}
              />
              {stateError && (
                <Typography
                  color="error"
                  sx={{ marginBottom: 2, marginTop: -1, textAlign: 'left' }}
                >
                  {stateError}
                </Typography>
              )}
              <Autocomplete
                disablePortal
                options={countries}
                renderInput={(params) => (
                  <TextField {...params} label="Country" required />
                )}
                ref={countryRef}
                sx={{ marginBottom: 1 }}
                defaultValue={
                  user_location?.country === 'United States' ||
                  user_location?.country === 'India' ||
                  user_location?.country === 'New Zealand'
                    ? user_location?.country
                    : ''
                }
              />
              {countryError && (
                <Typography
                  color="error"
                  sx={{ marginBottom: 2, marginTop: -1, textAlign: 'left' }}
                >
                  {countryError}
                </Typography>
              )}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox ref={defaultDeliveryAddressRef} defaultChecked />
                  }
                  label="Make this default delivery address"
                />
              </FormGroup>
              <div className="button-container">
                <button
                  className="secondary-button"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
                <button className="primary-button" onClick={save}>
                  Save
                </button>
              </div>
              {error && (
                <Typography
                  color="error"
                  sx={{ marginTop: 2, textAlign: 'center' }}
                >
                  {error}
                </Typography>
              )}
            </Box>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    );
  };

  return (
    <Box
      component={Paper}
      elevation={6}
      square
      sx={{
        marginTop: 5,
        paddingTop: 1,
        paddingBottom: 2,
        paddingInline: 2,
        borderRadius: '10px',
        boxShadow: '0px 5px 20px 0px rgba(0,0,0,0.2)',
      }}
    >
      <Box
        sx={{
          borderBottom: '1px solid rgba(201, 205, 210, 0.28)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBlock: 1,
        }}
      >
        <Typography>{!isDelivery ? 'Store ' : 'Delivery '}Address</Typography>
        {isDelivery && (
          <ButtonGroup>
            <Button
              style={{
                borderColor: '#FF6B15',
                color: '#FF6B15',
                borderRadius: '20px',
                borderWidth: '2px',
                paddingBlock: '1px',
                textTransform: 'capitalize',
              }}
              onClick={() => setIsOpen(true)}
            >
              Change
            </Button>
          </ButtonGroup>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'start',
          gap: 1.5,
          marginTop: 2,
        }}
      >
        <img src={map} alt="map" />
        <Typography style={{ fontSize: '14px', color: '#424A55' }}>
          {showMap(isDelivery, user_location, store_location)}
        </Typography>
      </Box>
      {
        !(isDelivery && !user_location) ? (
        <iframe
          height={200}
          style={{
            border: 0,
            borderRadius: '10px',
            marginTop: '25px',
            width: '100%',
          }}
          loading="lazy"
          title="store"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBeS_zC0hY6rvw887k7yKMysc3wPyc2bkw
          &q=${showMap(isDelivery, user_location, store_location)}`}
        />
        ):(null)
      }

      <LocationModal />
    </Box>
  );
};

const AlertModal = ({ isOpen, setIsOpen, orderType, setOrderType_ }) => {
  const [tempState, setTempState] = useState(orderType);
  const dispatch = useDispatch();
  const save = (e) => {
    setOrderType_(tempState);
    setIsOpen(false);
    dispatch(setOrderType(tempState));
  };

  return (
    <Sheet isOpen={isOpen} onClose={() => setIsOpen(false)} snapPoints={[]}>
      <Sheet.Container>
        <Sheet.Content>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6">Change Order Type</Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={tempState}
              value={tempState}
              onChange={(e) => setTempState(e.target.value)}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="IN_STORE"
                control={<Radio />}
                label="Dine In"
              />
              <FormControlLabel
                value="ONLINE_PICKUP"
                control={<Radio />}
                label="Pickup"
              />
              <FormControlLabel
                value="ONLINE_DELIVERY"
                control={<Radio />}
                label="Delivery"
              />
            </RadioGroup>
            <div className="button-container">
              <div className="primary-button" onClick={save}>
                Save
              </div>
            </div>
          </Box>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

const DeliveryBox = ({ setOrderTypeModalOpen, orderType }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'start',
      gap: 1.5,
      marginTop: 5,
      paddingInline: 2,
    }}
  >
    <Box sx={{ display: 'flex', gap: 1.5 }}>
      <img src={deliveryIcon} alt="" style={{ width: '48px' }} />
      <Box>
        <Typography>Order Type</Typography>
        <Typography sx={{ fontSize: '14px', color: '#424A55' }}>
          {orderType === 'IN_STORE'
            ? 'Dine In'
            : orderType === 'ONLINE_PICKUP'
              ? 'Pickup'
              : 'Delivery'}
        </Typography>
      </Box>
    </Box>
    <ButtonGroup>
      <Button
        style={{
          borderColor: '#FF6B15',
          color: '#FF6B15',
          borderRadius: '20px',
          borderWidth: '2px',
          paddingBlock: '1px',
          textTransform: 'capitalize',
        }}
        onClick={() => setOrderTypeModalOpen(true)}
      >
        Change
      </Button>
    </ButtonGroup>
  </Box>
);

const CustomerInfoBox = (props) => {
  const { firstname, setFirstname } = props;
  const { lastname, setLastname } = props;
  const { phoneNumber, setPhoneNumber } = props;
  const { firstnameError } = props;
  const { phoneNumberError } = props;

  return (
    <Box
      sx={{
        marginTop: 5,
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        boxShadow: '0px 5px 20px 0px rgba(0,0,0,0.2)',
        p: 2,
        paddingBottom: 4,
      }}
    >
      <Box component="form" noValidate>
        <Typography
          style={{
            borderBottom: '1px solid rgba(201, 205, 210, 0.28)',
            paddingBottom: 3,
          }}
        >
          Personal Information
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          id="firstname"
          label="First Name"
          name="firstname"
          autoFocus
          value={firstname}
          onChange={(e) => setFirstname(e.currentTarget.value)}
          error={!!firstnameError}
          helperText={firstnameError}
          sx={{ marginTop: '25px' }}
        />
        <TextField
          margin="normal"
          fullWidth
          id="lastname"
          label="Last Name"
          name="lastname"
          value={lastname}
          onChange={(e) => setLastname(e.currentTarget.value)}
        />
        <PhoneNumberInput
          value={phoneNumber}
          onChange={(value) => setPhoneNumber(value)}
          required
          style={{ marginTop: '15px' }}
        />
        {phoneNumberError && (
          <Typography color="error">{phoneNumberError}</Typography>
        )}
      </Box>
    </Box>
  );
};

const TimeBox = ({ orderType, time, setTime }) => {
  const order = useMemo(() => {
    return orderType === 'IN_STORE'
      ? 'Dine In'
      : orderType === 'ONLINE_PICKUP'
        ? 'Pickup'
        : 'Delivery';
  }, [orderType]);

  return (
    <Box
      sx={{
        marginTop: 5,
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        boxShadow: '0px 5px 20px 0px rgba(0,0,0,0.2)',
        p: 2,
        paddingBottom: 2,
      }}
    >
      <Typography
        style={{
          borderBottom: '1px solid rgba(201, 205, 210, 0.28)',
          paddingBottom: 8,
          display: 'flex',
          alignItems: 'center',
          gap: '6px',
        }}
      >
        <AccessTime sx={{ color: '#FF6B15' }} />
        Set {order} Time
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 1.5,
          marginTop: 2,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            label="Order Time"
            value={time}
            onChange={(val) => setTime(val)}
            sx={{ width: '100%' }}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default function CustomerInfoPage() {
  const user = useSelector((state) => state.auth.user);
  const cart = useSelector((state) => state.cart);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const [orderType, setOrderType] = useState(cart.orderType);
  const [orderTypeModalOpen, setOrderTypeModalOpen] = useState(false);

  const [firstname, setFirstname] = useState(cart.firstname || user.firstname);
  const [phoneNumber, setPhoneNumber] = useState(
    cart.phoneNumber || user.phoneNumber,
  );
  const [phoneNumberError, setPhoneNumberError] = useState();

  const [firstnameError, setFirstnameError] = useState('');
  const [lastname, setLastname] = useState(cart.lastname || user.lastname);

  const [customLocation, setCustomLocation] = useState('');
  const [orderTime, setOrderTime] = useState(dayjs());

  const user_location = useSelector((state) => state.auth.location);
  const store_location = useSelector((state) => state.store?.address);
  const cart_location = useSelector((state) => state.cart.location);

  const navigate = useNavigate();

  const [createOrder] = useCreateOrderMutation();

  const _createOrder = async () => {
    // if (!cart.order.pk) {
    // const lat = cart.location.deliveryLocation.latitude
    // const long = cart.location.deliveryLocation.longitude
    console.log("1st in Create Order")
    const isDelivery = orderType === 'ONLINE_DELIVERY';
    console.log("isDelivery is defined: ", isDelivery);
    const loc =
      cart.location?.country === 'United States'
        ? 'US'
        : cart.location?.country === 'India'
          ? 'IN'
          : 'NZ';
    console.log("Loc is defined: ", loc);
    console.log(
      'PRE - ORDER VALUE: ',
      cart.table,
      cart.phoneNumber,
      cart.firstname,
      cart.lastname,
      cart.store.pk,
      cart.orderType,
      cart.location,
    )
    // if(!(cart.phoneNumber && cart.firstname && cart.lastname)) {
    //   setLoading(false)
    //   return;
    // }
    let orderValue = {
      waiterId: null,
      tableId: cart.table,
      numOfGuests: 0,
      consumerId: null,
      phoneNumber: phoneNumber?.replace(/\s/g, ''),
      firstname: firstname,
      lastname: lastname,
      storeId: cart.store.pk,
      orderType: cart.orderType,
      // deliveryLocation: `${lat ? lat:""} ${long? long:""}`,
      addressLine1: isDelivery ? cart.location.addressLine1 : '',
      addressLine2: isDelivery ? cart.location.addressLine2 : '',
      city: isDelivery ? cart.location.city : '',
      state: isDelivery ? cart.location.state : '',
      country: isDelivery ? loc : '',
    };
    console.log("order value set")
    if (isDelivery) {
      orderValue['zip'] = cart.location.zip || '';
    }
    console.log("order zip set")
    orderValue['items'] = cart.products.map((item) => {
      let dd = {};
      dd['productId'] = item.pk;
      dd['quantity'] = item.quantity;
      dd['extraInfo'] = JSON.stringify({
        notes: item.extraInfo,
        guest: item.guest,
      });

      let components = [];
      Object.keys(item.optional).map((component) => {
        item.optional[component].map((item) => {
          components.push({ componentId: item.pk });
        });
      });
      Object.keys(item.required).map((component) => {
        components.push({ componentId: item.required[component].pk });
        return { componentId: component.pk };
      });
      dd['components'] = components;
      return dd;
    });
    console.log("order items set")
    const response = await createOrder(orderValue);
    return response;
    // }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let location_ = {};
    if (orderType === 'ONLINE_DELIVERY') {
      location_ = {
        addressLine1: cart_location.addressLine1 || cart_location.road,
        addressLine2:
          cart_location.addressLine2 ||
          cart_location.residential +
            cart_location.suburb +
            cart_location.municipality ||
          '',
        city: cart_location.city || cart_location.city,
        state: cart_location.state || cart_location.state,
        country: cart_location.country || cart_location.country,
        zip: cart_location.zip || cart_location.postcode || '',
      };
    } else {
      location_ = {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
      };
    }

    const data = {
      firstname,
      lastname,
      phoneNumber,
      location: location_,
      time: orderTime.toISOString(),
    };

    schema
      .validate(data)
      .then(async () => {
        setLoading(true);
        dispatch(setCustomerInfo(data));
        dispatch(setOrder({ delivery: { location: user_location } }));
        console.log('Set State Executed');
        if (cart.order.pk) {
          navigate('/payment');
          return;
        }
        console.log('Create Order Started');
        const response = await _createOrder();
        console.log('Create Order Executed');
        if (response.error) {
          // console.log(response.error.message);
          setLoading(false);
          setError('Failed to create order. Please try again later.');
          return;
        }
        dispatch(setOrder({total: getTotal(cart.products).total}))
        setLoading(false);
        navigate('/payment');
      })
      .catch((e) => {
        if (e.path === 'phoneNumber') setPhoneNumberError(e.message);
        if (e.path === 'firstname') setFirstnameError(e.message);
      });
  };

  return (
    <>
      <Header title="Customer information" withBackBtn />
      {error && (
        <Alert severity="error" variant="filled" onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      {loading ? (
        <SpinLoader />
      ) : (
        <>
          <Container component="main" maxWidth="md" sx={{ paddingBottom: 10 }}>
            <DeliveryBox
              setOrderTypeModalOpen={setOrderTypeModalOpen}
              orderType={orderType}
            />
            <AddressBox
              orderType={orderType}
              user_location={cart_location}
              store_location={store_location}
              customLocation={customLocation}
              setCustomLocation={setCustomLocation}
              setGlobalError={setError}
            />
            <TimeBox
              orderType={orderType}
              time={orderTime}
              setTime={setOrderTime}
            />
            <CustomerInfoBox
              cart={cart}
              user={user}
              firstname={firstname}
              setFirstname={setFirstname}
              lastname={lastname}
              setLastname={setLastname}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              phoneNumberError={phoneNumberError}
              setPhoneNumberError={setPhoneNumberError}
              firstnameError={firstnameError}
              setFirstnameError={setFirstnameError}
            />
            <div>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, backgroundColor: '#FF6B15' }}
                onClick={handleSubmit}
              >
                Save and proceed to payment
              </Button>
            </div>
          </Container>
          <AlertModal
            isOpen={orderTypeModalOpen}
            setIsOpen={setOrderTypeModalOpen}
            orderType={orderType}
            setOrderType_={setOrderType}
          />
        </>
      )}
    </>
  );
}

// useMemo(async () => {
//   if(localStorage.getItem('cities') && localStorage.getItem('states')){
//     setCities(JSON.parse(localStorage.getItem('cities')));
//     setStates(JSON.parse(localStorage.getItem('states')));
//     return;
//   }
//   const us = await axios.post(
//     'https://countriesnow.space/api/v0.1/countries/states',
//     { country: 'United States' },
//   );
//   const ind = await axios.post(
//     'https://countriesnow.space/api/v0.1/countries/states',
//     { country: 'India' },
//   );
//   const nz = await axios.post(
//     'https://countriesnow.space/api/v0.1/countries/states',
//     { country: 'New Zealand' },
//   );
//   const usStates = us.data.data.states;
//   const indStates = ind.data.data.states;
//   const nzStates = nz.data.data.states;
//   const states = usStates.concat(indStates).concat(nzStates);
//   const statesList = states.map((state) => ({
//     label: state.name,
//   }));
//   setStates(statesList);

//   const us_c = await axios.post(
//     'https://countriesnow.space/api/v0.1/countries/cities',
//     { country: 'United States' },
//   );
//   const ind_c = await axios.post(
//     'https://countriesnow.space/api/v0.1/countries/cities',
//     { country: 'India' },
//   );
//   const nz_c = await axios.post(
//     'https://countriesnow.space/api/v0.1/countries/cities',
//     { country: 'New Zealand' },
//   );
//   const usCities = us_c.data.data;
//   const indCities = ind_c.data.data;
//   const nzCities = nz_c.data.data;
//   const cities = usCities.concat(indCities).concat(nzCities);
//   const citiesList = cities.map((city) => ({
//     label: city,
//     key: city
//   }));
//   setCities(citiesList);
//   localStorage.setItem('cities', JSON.stringify(citiesList));
//   localStorage.setItem('states', JSON.stringify(statesList));
// }, []);
