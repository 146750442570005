import React from 'react';
import PhoneInput from 'react-phone-number-input';

const PhoneNumberInput = ({ value, onChange, ...props }) => {
  return (
    <PhoneInput
      international
      countries={['US', 'IN', 'NZ']}
      defaultCountry="IN"
      value={value}
      limitMaxLength={true}
      maxLength={15}
      onChange={onChange}
      countryCallingCodeEditable={false}
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default PhoneNumberInput;
