import React from 'react';
import ContentLoader from 'react-content-loader';

const MyLoader = (props) => (
  <ContentLoader
    speed={2}
    width={window.innerWidth - 32}
    height={124}
    viewBox={`0 0 ${window.innerWidth} 124`}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect
      x={(window.innerWidth / 3) * 2}
      y="3"
      rx="8"
      ry="8"
      width={window.innerWidth / 3}
      height="107"
    />
    <rect x="0" y="27" rx="8" ry="8" width="209" height="14" />
    <rect x="0" y="10" rx="6" ry="6" width="151" height="11" />
    <rect x="0" y="49" rx="6" ry="6" width="54" height="10" />
    <rect x="0" y="67" rx="6" ry="6" width="230" height="9" />
  </ContentLoader>
);

export default MyLoader;
