import { gql } from '@apollo/client';

import { api } from './Api';

// export const PRODUCTS_LIST = gql`
//   query Products(
//     $slug: String
//     $first: Int
//     $offset: Int
//     $category: String
//     $name: String
//   ) {
//     products(
//       isActive: true
//       stores_Slug: $slug
//       first: $first
//       offset: $offset
//       category_Name: $category
//       name_Icontains: $name
//     ) {
//       pageInfo {
//         hasNextPage
//       }
//       edges {
//         node {
//           pk
//           id
//           name
//           priceCurrency
//           price
//           shortDescription
//           description
//           outOfStock
//           priceAmount
//           appliedTaxes {
//             edges {
//               node {
//                 pk
//                 name
//                 taxType
//                 valueType
//                 valueAmount
//                 valueDisplay
//               }
//             }
//           }
//           category {
//             name
//             pk
//           }
//           pictures {
//             edges {
//               node {
//                 pk
//                 picture
//                 order
//                 url
//               }
//             }
//           }
//           productComponentsGroups(isActive: true) {
//             edges {
//               node {
//                 id
//                 pk
//                 name
//                 isRequired
//                 minNumOfComponents
//                 maxNumOfComponents
//                 components(isActive: true) {
//                   edges {
//                     node {
//                       id
//                       pk
//                       shortDescription
//                       price
//                       priceCurrency
//                     }
//                   }
//                 }
//                 extraInfo
//               }
//             }
//           }
//           availabilities {
//             edges {
//               node {
//                 id
//                 isAvailable
//                 startTime
//                 endTime
//               }
//             }
//           }
//           quantities {
//             edges {
//               node {
//                 quantity
//               }
//             }
//           }
//           stores(slug: $slug) {
//             edges {
//               node {
//                 pk
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;

export const PRODUCTS_LIST = gql`
query Products(
  $slug: String

) {
  products(
    isActive: true
    stores_Slug: $slug

  ) {

    edges {
      node {
        pk
        id
        name
        priceCurrency
        price
        shortDescription
        description
        outOfStock
        priceAmount
        appliedTaxes {
          edges {
            node {
              pk
              name
              taxType
              valueType
              valueAmount
              valueDisplay
            }
          }
        }
        category {
          name
          pk
        }
        pictures {
          edges {
            node {
              pk
              picture
              order
              url
            }
          }
        }
        productComponentsGroups(isActive: true) {
          edges {
            node {
              id
              pk
              name
              isRequired
              minNumOfComponents
              maxNumOfComponents
              components(isActive: true) {
                edges {
                  node {
                    id
                    pk
                    shortDescription
                    price
                    priceCurrency
                  }
                }
              }
              extraInfo
            }
          }
        }
        availabilities {
          edges {
            node {
              id
              isAvailable
              startTime
              endTime
            }
          }
        }
        quantities {
          edges {
            node {
              quantity
            }
          }
        }
        stores(slug: $slug) {
          edges {
            node {
              pk
            }
          }
        }
      }
    }
  }
}
`;

const CATEGORIES_QUERY = gql`
  query ($storeSlug: String) {
    productCategories(storeSlug: $storeSlug) {
      edges {
        node {
          pk
          id
          name
          products {
            count
          }
        }
      }
    }
  }
`;

let prevArg;
const ProductsApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    GetProducts: build.query({
      query: (variables) => {
        if (
          prevArg &&
          (prevArg.category !== variables.category ||
            prevArg.search !== variables.search)
        ) {
          variables = { ...variables, offset: 0 };
        }
        if (variables.offset === 0 && !prevArg) {
          prevArg = variables;
        }
        return {
          document: PRODUCTS_LIST,
          variables: variables,
        };
      },
      transformResponse: (response) => ({
        products: response.products.edges.map((edge) => edge.node),
        pageInfo: response.products.pageInfo,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { slug } = queryArgs;
        return `${endpointName}(${slug})`;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      merge: (currentCache, newItems, { arg }) => {
        // Do not merge when category s changed
        if (prevArg && arg.category !== prevArg.category) {
          prevArg = arg;
          return newItems;
        }

        // Do not merge when searching
        if (prevArg && arg.name !== prevArg.name) {
          prevArg = arg;
          return newItems;
        }

        prevArg = arg;
        currentCache.pageInfo = newItems.pageInfo;
        currentCache.products.push(...newItems.products);
      },
    }),
    GetProductsCategories: build.query({
      query: (variables) => ({
        document: CATEGORIES_QUERY,
        variables,
      }),
      transformResponse: (response) => [
        { pk: 0, name: 'All', id: '1' },
        ...response.productCategories.edges.map((edge) => edge.node),
      ],
    }),
  }),
});

export { ProductsApi as api };
export const {
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useGetProductsCategoriesQuery,
} = ProductsApi;
