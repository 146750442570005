import { getTotal } from '../helpers/utils';

export default function Invoice(props) {
  const { subTotal, total, taxes } = getTotal(props.products);
  const discount = props.discount;
  const subTotalOrder = props.subTotal;
  const totalOrder = props.total;
  const currency = props.store?.currency;

  console.log(discount)
  
  return (
    <div className="invoice-container">
      <div className="invoice-item">
        <div className="subtotal">Discount</div>
        <div className="invoice-price">
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
          }).format(discount)}
        </div>
      </div>
      <div className="invoice-item">
        <div className="subtotal">Subtotal</div>
        <div className="invoice-price">
          {subTotalOrder ||
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency,
            }).format(subTotal)}
        </div>
      </div>
      {Object.keys(taxes).length === 0 ? (
        <div className="invoice-item" key={0}>
          <div className="invoice-title">Tax</div>
          <div className="invoice-price">
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency,
            }).format(0)}
          </div>
        </div>
      ) : (
        Object.keys(taxes).map((tax, idx) => (
          <div className="invoice-item" key={idx}>
            <div className="invoice-title">{tax}</div>
            <div className="invoice-price">
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency,
              }).format(taxes[tax])}
            </div>
          </div>
        ))
      )}
      <hr style={{ borderTop: '1px dotted #73717d' }} />
      <div className="invoice-item">
        <div className="total">Grand Total</div>
        <div className="total-price">
          {totalOrder ||
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency,
            }).format(total - discount)}
        </div>
      </div>
    </div>
  );
}
