import React from 'react';
import { useNavigate } from 'react-router-dom';

import Heart from '../assets/icons/heart.svg';
import Menu from '../assets/icons/menu.svg';
import '../assets/stylesheets/homeItemCard.scss';

export default function StoreCard(props) {
  const { logo, shortDescription, slug, name, pictures, address } = props;
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/restaurants/${slug}`)}
      className="home-item-card"
    >
      <div className="ovelay" />
      <img
        src={pictures.edges.length > 0 ? pictures.edges[0].node.url : logo}
        style={{
          // maxHeight: 150,
          objectFit: 'cover',
          width: '100%',
          borderBottom: '1px solid #ddd',
          aspectRatio: "16/9",
        }}
        alt='store logo'
      />
      <div className="content">
        <div className="container">
          <img src={logo} alt={`${name} logo`} />
          <div className="info">
            <p className="title">{name}</p>
            <p className="description">{shortDescription}</p>
            <p className="address">{address}</p>
          </div>
        </div>
      </div>
      <div className="action-buttons">
        <img src={Heart} alt='favourite' />
        <img src={Menu} alt='Menu' />
      </div>
    </div>
  );
}
