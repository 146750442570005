import { useSubscription } from '@apollo/client';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import CallIcon from '@mui/icons-material/Call';
import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import Header from '../components/Header';
import SpinLoader from '../components/SpinLoader';
import VerticalLinearStepper from '../components/Stepper';
import { emptyCart } from '../store/reducers/CartReducer';
import {
  SUBSCRIPE_ORDER,
  useCompleteOrderMutation,
  useGetOrderQuery,
} from '../store/services/OrderApi';

const status = {
  PLACED: 0,
  ACCEPTED: 1,
  COMPLETED: 2,
};

function TrackOrder() {
  const navigate = useNavigate();
  const { id } = useParams();
  const auth = useSelector((state) => state.auth);
  const { data: order, isLoading, refetch } = useGetOrderQuery({ id: id });
  const { data } = useSubscription(SUBSCRIPE_ORDER, {
    variables: {
      token: auth.accessToken,
      order: id,
    },
  });

  const [openCall, setOpenCall] = useState(false);
  const [completeOrder] = useCompleteOrderMutation();
  const [loading, setLoading] = useState(false);

  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  // Only for Stripe
  const status = new URLSearchParams(window.location.search).get(
    'redirect_status',
  );
  useEffect(() => {
    async function markOrder() {
      setLoading(true);
      const res = await completeOrder({
        id: cart.order.pk,
        paymentType: 'CREDIT_CARD',
        cashReceived: cart.total,
        paymentGatewayId: cart.activePaymentGateway,
        change: parseFloat(0),
        paymentGatewayParams: JSON.stringify({ payment_id: '' }),
      });
      if (!res.data.checkoutOrder.success) {
        setLoading(false);
        return;
      }
      // setPaymentGatewayPk(gateway.pk);
      dispatch(emptyCart());
      refetch()
    }
    if (status === 'succeeded') {
      markOrder();
      setLoading(false);
    }
  }, [status]);

  return (
    <>
      {loading ? (
        <SpinLoader />
      ) : (
        <>
          <Header title={`Order#: ${id}`} withDrawer />
          <div
            className="track-container"
            style={{ maxWidth: '800px', margin: 'auto' }}
          >
            {isLoading ? (
              <SpinLoader />
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: 10,
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      height: 72,
                      width: 72,
                      borderRadius: 36,
                      background: '#ddd',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={order?.store?.logo}
                      style={{ height: 48, width: 48, objectFit: 'cover' }}
                      alt=""
                    />
                  </div>
                  <Typography
                    variant="h6"
                    align="center"
                    style={{ marginTop: 10 }}
                  >
                    {order.store.name}
                  </Typography>
                  <Typography
                    variant="caption"
                    align="center"
                    style={{ marginBottom: 30 }}
                  >
                    {order?.store?.address}
                  </Typography>

                  <div
                    onClick={() => navigate(`/order/${id}`)}
                    className="caption strong underline"
                  >
                    View Receipt
                  </div>

                  <VerticalLinearStepper
                    activeStep={
                      data ? data.order.order.orderStatus : order.orderStatus
                    }
                    order={order}
                  />
                </div>

                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={() => {
                    setOpenCall(true);
                  }}
                  style={{ marginTop: 40, textTransform: 'none' }}
                >
                  Contact Store
                </Button>

                <Drawer
                  anchor="bottom"
                  open={openCall}
                  onClose={() => setOpenCall(false)}
                >
                  <Box onClick={() => {}} onKeyDown={() => {}}>
                    <List>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() => {
                            window.open(`tel:${order?.store?.phoneNumber}`);
                          }}
                        >
                          <ListItemIcon>
                            <CallIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={order?.store?.phoneNumberDisplay}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Box>
                </Drawer>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default TrackOrder;
