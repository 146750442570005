import { useMutation } from '@apollo/client';
import { loadStripe } from '@stripe/stripe-js';

import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useEffect, useMemo, useState } from 'react';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PaymentIcon from '@mui/icons-material/Payment';
import { Alert, Box, Button, Typography } from '@mui/material';

import { useDispatch } from 'react-redux';
import { setActivePaymentGateway } from '../../store/reducers/CartReducer';
import { useCompleteOrderMutation } from '../../store/services/OrderApi';
import { GET_STRIPE_PAYMENT_INTENT } from '../../store/services/StripeApi';

const CheckoutForm = ({
  setShowForm,
  clientSecret,
  cart,
  onPaymentGatewayParamsResolved,
  gateway,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [completeOrder] = useCompleteOrderMutation();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openError, setOpenError] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    
    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenError(false)
    setMessage(null)

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    
    dispatch(setActivePaymentGateway(gateway.pk))
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `https://order.rderly.com/order/${cart.order.orderId}/track`,
        // return_url: `http://localhost:3000/order/${cart.order.orderId}/track`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
      setOpenError(true);
    } else {
      setOpenError(true);
      setMessage('An unexpected error occurred.');
    }
    setIsLoading(false);
  };

  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit}
      sx={{
        backgroundColor: '#FFF',
        p: 4,
        borderRadius: 4,
        border: '1px solid #f9f9f9',
        boxShadow: '0px 5px 20px 0px rgba(0,0,0,0.05)',
        m: 2,
      }}
    >
      <Typography variant="h6">Card Details</Typography>
      <PaymentElement />
      {(message && openError) && 
        <Alert severity="error" variant='filled' sx={{ mt:2 }}>
          {message}
        </Alert>
      }
      <Button
        type="submit"
        variant="contained"
        fullWidth
        sx={{ mt: 3 }}
        disabled={!stripe || isLoading}
      >
        Pay
      </Button>
      <Button
        type="cancel"
        variant="outlined"
        fullWidth
        sx={{ mt: 1 }}
        onClick={() => setShowForm(false)}
      >
        Cancel
      </Button>
    </Box>
  );
};

const StripeForm = ({
  settings,
  gateway,
  onPaymentGatewayParamsResolved,
  cart,
  onLoading,
}) => {
  const stripePromise = useMemo(() => loadStripe(settings.api_key), [settings]);
  const [clientSecret, setClientSecret] = useState("");

  const [getIntent] = useMutation(GET_STRIPE_PAYMENT_INTENT);

  useEffect(() => {
    async function fetchIntent() {
      const resp = await getIntent({
        variables: {
          orderId: cart.order.id,
        },
      });
      setClientSecret(resp.data.stripeCreatePaymentIntent.clientSecret);
    }
    fetchIntent();
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  const [showForm, setShowForm] = useState(false);

  return (
    <>
      {!showForm ? (
        <div
          onClick={() => setShowForm(true)}
          style={{
            borderRadius: 8,
            backgroundColor: '#FFFFFF',
            height: 50,
            flexShrink: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: 10,
            paddingLeft: 10,
            margin: '5px 15px 5px 15px',
            cursor: 'pointer',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PaymentIcon />
            <p style={{ fontFamily: 'Poppins', marginLeft: 10, fontSize: 16 }}>
              Pay Using Stripe
            </p>
          </div>
          <KeyboardArrowRightIcon />
        </div>
      ) : (
        clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              setShowForm={setShowForm}
              settings={settings}
              onPaymentGatewayParamsResolved={onPaymentGatewayParamsResolved}
              clientSecret={clientSecret}
              cart={cart}
              gateway={gateway}
            />
          </Elements>
        )
      )}
    </>
  );
};

export default StripeForm;
