import { useMutation, useQuery } from '@apollo/client';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { LocalOfferOutlined } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { ArrowRightIcon } from '@mui/x-date-pickers';

import AddItem from '../components/AddItem';
import CartItem from '../components/CartItem';
import Header from '../components/Header';
import Invoice from '../components/Invoice';
import MyLoader from '../components/Loader';
import PlaceOrderButton from '../components/PlaceOrderButton';
import { calculateQuantitySum, countryCodes, removeItemFromDb, updateItemToDb } from '../helpers/utils';
import {
  addButton,
  addItem,
  addQuantityByButton,
  reduceQuantityByButton,
  setOrder
} from '../store/reducers/CartReducer';
import { GET_ORDER, REMOVE_ITEM, UPDATE_ITEM, useCreateOrderMutation } from '../store/services/OrderApi';

export default function CartPage(props) {
  const cart = useSelector((state) => state.cart);
  const auth = useSelector((state) => state.auth);
  const user = auth.user;
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [createOrder] = useCreateOrderMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [invoiceAmounts, setInvoiceAmounts] = useState({});
  const [discountId, setDiscountId] = useState(null);
  const [discountCode, setDiscountCode] = useState(null);

  const [removeItemDb] = useMutation(REMOVE_ITEM)
  const [updateItem] = useMutation(UPDATE_ITEM)
  const [dbOrder, setDbOrder] = useState(null);
  
  const res = useQuery(GET_ORDER, {
    variables: { id: cart.order?.id },
    skip: !cart.order?.id,
  });
  const isLoading = res.loading;
  
  const onAddPress = (item) => {
    dispatch(addButton(item));
  };
  
  const onAddQuantity = async (itemId) => {
    try {
      dispatch(addQuantityByButton(itemId));
      await updateItemToDb(itemId, cart, dbOrder, updateItem, true, res, dispatch, setError);
    } catch (error) {
      setError("Error updating the item quantity. Please try again later.")
      dispatch(reduceQuantityByButton(itemId));
    }
  };
  

  const onReduceQuantity = async (itemId) => {
    try {
      console.log("item id: ",itemId)
      dispatch(reduceQuantityByButton(itemId));
      const item = cart.products.find((item) => item.id === itemId);
      if (item.quantity === 1) {
        await removeItemFromDb(item, cart, dbOrder, removeItemDb, res, dispatch, setError);
        return;
      }
      await updateItemToDb(itemId, cart, dbOrder, updateItem, false, res, dispatch, setError);
    } catch (error) {
      setError("Error updating the item quantity. Please try again later.")
      dispatch(addQuantityByButton(itemId));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onAddItem = (item) => {
    console.log("ADD ITEM CALLED ON CART, ITEM: ", item)
    dispatch(addItem(item));
  };

  const handleClickOpen = (item) => {
    setOpen(true);
    setProduct(item);
  };

  const onAddMoreItems = () => {
    return navigate(`/restaurants/${cart.store.slug}`);
  };

  const onPlaceOrder = () => {
    if (!auth.isLoggedIn) {
      return navigate(`/login/`, { state: '/customerInfo' });
    } else {
      navigate('/customerInfo');
    }
  };

  const _createOrder = async () => {
    // if (!cart.order.pk) {
    // const lat = cart.location.deliveryLocation.latitude
    // const long = cart.location.deliveryLocation.longitude
    const isDelivery = cart.orderType === 'ONLINE_DELIVERY';
    const location = auth.location;
    const loc = countryCodes[auth.location?.country] || '';
    const phoneNumber = user.phoneNumber?.replace(/\s/g, '');
    const firstname = user?.firstname || '';
    const lastname = user?.lastname || '';
    const storeId = cart.store.pk;
    const orderType = cart.orderType;
    const addressLine1 = isDelivery ? location?.addressLine1 : '';
    const addressLine2 = isDelivery ? location?.addressLine2 : '';
    const city = isDelivery ? location?.city : '';
    const state = isDelivery ? location?.state : '';
    const country = isDelivery ? loc : '';
    const zip = isDelivery ? location?.postcode || location?.zip || '' : '';
    const items = cart.products.map((item) => {
      let dd = {};
      dd['productId'] = item.pk;
      dd['quantity'] = item.quantity;
      dd['extraInfo'] = JSON.stringify({
        notes: item.extraInfo,
        guest: item.guest,
      });

      let components = [];
      Object.keys(item.optional).map((component) => {
        item.optional[component].map((item) => {
          components.push({ componentId: item.pk });
        });
      });
      Object.keys(item.required).map((component) => {
        components.push({ componentId: item.required[component].pk });
        return { componentId: component.pk };
      });
      dd['components'] = components;
      return dd;
    });
    const orderValue = {
      waiterId: null,
      tableId: null,
      numOfGuests: 0,
      consumerId: null,
      phoneNumber,
      firstname,
      lastname,
      storeId,
      orderType,
      addressLine1,
      addressLine2,
      city,
      state,
      country,
      zip,
      items,
    };

    const response = await createOrder(orderValue);
    return response;
  };

  const onCheckDiscount = async () => {
    if (!auth.isLoggedIn) {
      navigate(`/login/`, { state: '/cart' });
    } else {
      // check if order is already created or not
      if (cart.order.pk) {
        navigate('/promotions', { state: { discountId, discountCode } });
      } else {
        const response = await _createOrder();
        if (response.error) {
          setError('Failed to create order. Please try again later.');
        } else {
          navigate('/promotions');
        }
      }
    }
  };



  useEffect(() => {
    if (res.data !== undefined) {
      setDbOrder(res?.data?.order)
      setDiscountId(res?.data?.order?.discounts?.edges[0]?.node?.pk);
      setDiscountCode(res?.data?.order?.discounts?.edges[0]?.node?.offer?.code);
      const amounts = {
        subTotal: res?.data?.order?.subtotal,
        total: res?.data?.order?.totalAmount,
        discount: parseFloat(res?.data?.order?.discounted.replace("₹", "")) || 0,
        taxRate: res?.data?.order?.tax,
      };
      setInvoiceAmounts(amounts);
      dispatch(setOrder(amounts));
      setSuccessOpen(true)
    } else {
      setDiscountId(null);
      setDiscountCode(null);
    }
  }, [res]);

  const [successOpen, setSuccessOpen] = useState(false);
  
  return (
    <>
      <Header title={cart?.store?.name} withBackBtn />
      {error && <Alert severity="error">{error}</Alert>}
      { (discountId && successOpen) && <Alert severity="success" variant='filled' onClose={()=>setSuccessOpen(false)} sx={{ maxWidth: '780px', mx: "auto" }}>Promotion applied successfully!</Alert>}
      <div
        className="cart-container"
        style={{
          marginTop: "24px",
          marginBottom: 70,
          maxWidth: '800px',
          margin: 'auto',
          paddingInline: 10,
        }}
      >
        <span onClick={onAddMoreItems} className="add-item">
          Add Items
        </span>
        <div className="items-container">
          {cart.products.map((item, index) => (
            <CartItem
              handleClickOpen={handleClickOpen}
              isExist={calculateQuantitySum(cart.products, item.id)}
              onAddQuantity={() => onAddQuantity(item.id)}
              onReduceQuantity={() => onReduceQuantity(item.id)}
              onAddPress={() => onAddPress(item)}
              item={item}
              key={index}
            />
          ))}
        </div>
        <div
          style={{
            marginTop: '30px',
            textTransform: 'capitalize',
            fontWeight: 500,
            fontFamily: 'poppins',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '10px',
            fontSize: '18px',
          }}
          onClick={onCheckDiscount}
        >
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <LocalOfferOutlined />
            Check avaialable promotions
          </div>
          <ArrowRightIcon />
        </div>
        {isLoading ? <MyLoader /> : <Invoice {...cart} {...invoiceAmounts} />}
        <PlaceOrderButton onPress={onPlaceOrder} />
        <AddItem
          open={open}
          handleClose={handleClose}
          product={product}
          addProducts={onAddItem}
        />
      </div>
    </>
  );
}
