import { createSlice } from "@reduxjs/toolkit"
import { api } from "../services/StoreApi"

const StoreReducer = createSlice({
    name: 'store',
    initialState: {isLoading:false},
    reducers: {},
    extraReducers: (builder) => {
      builder.addMatcher(
        api.endpoints.GetStore.matchFulfilled,
        (state, { payload }) => {
          let newState = {...state,...payload,isLoading:false}
          return newState
        }
      )
      .addMatcher( api.endpoints.GetStore.matchPending,
        (state, { payload }) => {
          let newState = {isLoading:true}
          return newState
        })
    },
  })

//   export const { postAdded, postUpdated } = postsSlice.actions

export default StoreReducer.reducer