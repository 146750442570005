import React from 'react';
import { useNavigate } from 'react-router-dom';

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import PaymentsIcon from '@mui/icons-material/Payments';

import { useDispatch } from 'react-redux';
import { emptyCart } from '../../store/reducers/CartReducer';
import { useCompleteOrderMutation, useUpdateOrderStatusMutation } from '../../store/services/OrderApi';

const CashOnDelivery = ({
  settings,
  gateway,
  onPaymentGatewayParamsResolved,
  cart,
  onLoading,
}) => {
  const [completeOrder, { isSuccess: completeOrderSuccess }] = useCompleteOrderMutation();
  const [updateOrderStatus] = useUpdateOrderStatusMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = async () => {
    onLoading(true);
    const variables = {
      id: cart.order.pk,
      paymentType: 'CASH',
      cashReceived: cart.total,
      change: parseFloat(0),
      paymentGatewayParams: JSON.stringify({
        payment_id: 'COD',
      }),
    };
    const res = await completeOrder(variables);
    if(!res.data.checkoutOrder.success) {
      onLoading(false);
      return;
    }
    dispatch(emptyCart());
    await updateOrderStatus({
      orderId: cart.order.pk,
      status: 'PLACED',
    });
    onLoading(false);
    navigate(`/order/${cart.order.orderId}/track`, { replace: true });
  };

  return (
    <div
      onClick={handleClick}
      style={{
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        height: 50,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: 10,
        paddingLeft: 10,
        margin: '5px 15px 5px 15px',
        cursor: 'pointer',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <PaymentsIcon />
        <p style={{ fontFamily: 'Poppins', marginLeft: 10, fontSize: 16 }}>
          Cash on delivery
        </p>
      </div>
      <KeyboardArrowRight />
    </div>
  );
};

export default CashOnDelivery;
