import { gql } from '@apollo/client';

import { api } from './Api';

export const GetStoresQuery = gql`
  query Stores($name: String) {
    stores(name_Icontains: $name, disableOnlineOrders:false) {
      count
      edges {
        cursor
        node {
          id
          extraInfo
          isActive
          createdAt
          updatedAt
          deletedAt
          name
          slug
          location
          phoneNumber
          country
          currency
          state
          city
          street
          zipCode
          taxRate
          shortDescription
          longDescription
          deliveryCostPerMileCurrency
          deliveryCostPerMile
          deliveryDistanceLimitMiles
          totalRating
          disableOnlineOrders
          logo
          pictures {
            edges {
              node {
                url
              }
            }
          }
          orderPreparationTimeHours
          entitySource
          entitySourceId
          pk
          address
          countryDisplay
          isOpen
          deliveryCostPerMileAmount
          churnRate
        }
      }
    }
  }
`;

export const GetStoreQuery = gql`
  query Store($slug: ID!) {
    publicStore(id: $slug) {
      name
      slug
      location
      zipCode
      street
      city
      state
      country
      currency
      phoneNumber
      taxRate
      logo
      pk
      address
      isOpen
      id

    }
  }
`;
const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    GetStores: build.query({
      query: (variables) => ({ document: GetStoresQuery, variables }),
      transformResponse: (response) =>
        response.stores.edges.map((edge) => edge.node),
    }),
    GetStore: build.query({
      query: (variables) => ({
        document: GetStoreQuery,
        variables: { slug: variables },
      }),
      transformResponse: (response) => response.publicStore,
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetStoresQuery,
  useLazyGetStoresQuery,
  useGetStoreQuery,
  useLazyGetStoreQuery,
} = injectedRtkApi;
