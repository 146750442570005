import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { alpha, styled } from '@mui/material/styles';

import logo from '../assets/logo.png';
import Header from '../components/Header';
import PhoneNumberInput from '../components/PhoneNumberInput';
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from '../store/services/Profile';

const BootstrapInput = styled(TextField)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1A2027',
    border: '1px solid',
    borderColor: theme.palette.mode === 'light' ? '#ccc' : '#2D3843',
    fontSize: 16,
    width: '-webkit-fill-available',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const initalData = {
  email: null,
  firstname: null,
  lastname: null,
  phoneNumber: '',
  countryDisplay: '',
  city: null,
  zipCode: null,
  street: null,
  picture: null,
  merchantTypeDisplay: null,
  gender: null,
  genderDisplay: null,
  birthday: null,
};

function ProfilePage() {
  const { data } = useGetProfileQuery();
  const [profileData, setProfileData] = useState(initalData);
  const [updateProfile, { isSuccess, isError }] = useUpdateProfileMutation();
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect(() => {
    if (data) {
      setProfileData({ ...profileData, ...data });
    }
  }, [data]);

  const onChange = (name, e) => {
    setProfileData({ ...profileData, [name]: e.target.value });
  };

  const validateEmail = (email) => {
    // Basic email validation
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const onSubmit = async () => {
    if (!validateEmail(profileData.email)) {
      setMessage('Enter a valid email address.');
      setMessageColor('red');
      return;
    }

    try {
      const response = await updateProfile(profileData);
      if (
        response.data &&
        response.data.updateUser &&
        response.data.updateUser.success
      ) {
        setMessage('Profile updated successfully!');
        setMessageColor('green');
      } else {
        // Check if the error contains specific error messages
        if (
          response.data &&
          response.data.updateUser &&
          response.data.updateUser.errors
        ) {
          const errorMessage = Object.values(
            response.data.updateUser.errors,
          )[0][0].message;
          setMessage(errorMessage);
        } else {
          setMessage('Failed to update profile. Please try again.');
        }
        setMessageColor('red');
      }
    } catch (error) {
      setMessage('Failed to update profile. Please try again.');
      setMessageColor('red');
    }
  };

  return (
    <div style={{ background: '#FFFFFF', height: '100vh' }}>
      <Header title={`Profile`} withBackBtn />
      <div style={{ maxWidth: "800px", margin: 'auto' }}>
        <div style={{ position: 'relative', width: '100%', height: 55 }}>
          <div className="profile-pic-container">
            <img src={logo} style={{ height: 72, width: 72 }} />
          </div>
        </div>
        <div className="profile-container">
          <div style={{ marginTop: 40, marginBottom: 30 }}>
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                flexDirection: { sm: 'row', xs: 'column' },
              }}
            >
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  First Name
                </InputLabel>
                <BootstrapInput
                  onChange={(e) => onChange('firstname', e)}
                  value={profileData.firstname}
                  placeholder="First Name"
                  id="bootstrap-input"
                />
              </FormControl>
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="bootstrap-input">
                  Last Name
                </InputLabel>
                <BootstrapInput
                  onChange={(e) => onChange('lastname', e)}
                  value={profileData.lastname}
                  placeholder="Last Name"
                  id="bootstrap-input"
                />
              </FormControl>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gridTemplateColumns: { sm: '1fr 1fr' },
                gap: 2,
                marginTop: 2,
              }}
            >
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  style={{ position: 'initial' }}
                >
                  Phone number
                </InputLabel>

                <PhoneNumberInput
                  onChange={(value) =>
                    setProfileData({ ...profileData, phoneNumber: value })
                  }
                  value={profileData.phoneNumber}
                />
              </FormControl>
            </Box>

            <FormControl sx={{ marginTop: 2 }} fullWidth variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                Email address
              </InputLabel>
              <BootstrapInput
                placeholder="Email address"
                value={profileData.email}
                onChange={(e) => onChange('email', e)}
                id="bootstrap-input"
              />
            </FormControl>
            <FormControl sx={{ marginTop: 2 }} fullWidth variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                Date of Birth
              </InputLabel>
              <BootstrapInput
                placeholder="Date of Birth"
                value={profileData.birthday}
                onChange={(e) => onChange('birthday', e)}
                type="date"
                id="bootstrap-input"
              />
            </FormControl>
            <FormControl sx={{ marginTop: 2 }} fullWidth variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                Gender
              </InputLabel>
              <BootstrapInput
                select
                variant="filled"
                SelectProps={{
                  native: true,
                }}
                placeholder="Gender"
                value={profileData.gender}
                onChange={(e) => onChange('gender', e)}
                id="bootstrap-input"
              >
                <option key={'1'} value={'Male'}>
                  Male
                </option>
                <option key={'2'} value={'Female'}>
                  Female
                </option>
              </BootstrapInput>
            </FormControl>
          </div>
          <Button
            onClick={() => onSubmit()}
            variant="contained"
            fullWidth
            size="large"
          >
            Update Profile
          </Button>
        </div>
        {message && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 10,
              color: messageColor,
            }}
          >
            {message}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfilePage;
