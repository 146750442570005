import { useState } from 'react';
import OTPInput from 'react-otp-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Container, Link, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import logo from '../assets/logo.png';
import Header from '../components/Header';
import PhoneNumberInput from '../components/PhoneNumberInput';
import SpinLoader from '../components/SpinLoader';
import { setProfile } from '../store/reducers/UserReducer';
import {
  useGenerateOtpMutation,
  useVerifyOtpMutation,
} from '../store/services/Auth';
import client from '../store/services/Client';
import {
  GET_PROFILE_DATA,
  useUpdateProfileMutation,
} from '../store/services/Profile';

export default function LoginPage() {
  const dispatch = useDispatch();
  const [login, setLogin] = useState(true);
  const [otp, setOtp] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [error, setError] = useState(null);
  const [attemptCount, setAttemptCount] = useState(0); // New state to track OTP attempts
  const [generateOtp, { data: otpResult }] = useGenerateOtpMutation();
  const [verifyOtp] = useVerifyOtpMutation();
  const [updateProfile] = useUpdateProfileMutation();
  const [openUserInfoForm, setOpenUserInfoForm] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isValidPhoneNumber(phone)) {
      setLoading(true);
      await generateOtp({ phoneNumber: phone });
      setLogin(false);
      setLoading(false);
    } else {
      setError('This phone number is invalid');
    }
  };

  const handleOtp = async (event) => {
    event.preventDefault();
    if (otp.length === 6) {
      setLoading(true);
      verifyOtp({
        sessionId: otpResult.sessionId,
        otp: otp,
        phoneNumber: phone,
      })
        .unwrap()
        .then(async (payload) => {
          setOpenUserInfoForm(false);

          const profile = await client.query({
            query: GET_PROFILE_DATA,
          });

          dispatch(setProfile(profile.data.me));

          setLoading(false);

          const emailExists = profile.data.me.email !== null;
          const firstNameExists = profile.data.me.firstname !== null;
          const lastNameExists = profile.data.me.lastname !== null;

          // IF EMAIL, FIRST AND LAST NAMES EXIST, REDIRECT TO HOME PAGE
          if (emailExists && firstNameExists && lastNameExists) {
            return navigate(location.state || '/', { replace: true });
          }

          // ELSE OPEN USER INFO FORM
          setIsOtpVerified(true);
          setOpenUserInfoForm(true);
          return;
        })
        .catch((error) => {
          setLoading(false);
          if (error.message === 'Invalid OTP') {
            setError('Invalid OTP - Please try again.');
            setAttemptCount(attemptCount + 1);
          } else {
            setError('An unexpected error occurred. Please try again.');
          }
        });
    }
  };

  const handleResendOTP = async () => {
    setLoading(true);
    setOtp('');
    setError(null);
    await generateOtp({ phoneNumber: phone });
    setLoading(false);
    setAttemptCount(0); // Reset attempt count after resending OTP
  };

  const handleSaveSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const firstName = data.get('firstName');
    const lastName = data.get('lastName');

    if (!email || !firstName || !lastName) {
      setError('All fields are required');
      return;
    }

    setLoading(true);

    // Save user information
    updateProfile({
      email: email,
      firstname: firstName,
      lastname: lastName,
    })
      .unwrap()
      .then((payload) => {
        setLoading(false);
        return navigate(location.state || '/', { replace: true });
      })
      .catch((error) => {
        setLoading(false);
        setError('An unexpected error occurred. Please try again.');
      });
  };

  const PhoneNumberForm = () => {
    return (
      <Box sx={{ margin: 'auto' }} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ textAlign: 'left', mb: 4 }}>
            <Box sx={{ textAlign: 'center' }}>
              <img
                style={{
                  width: 48,
                  height: 48,
                  borderRadius: 99,
                  background: '#ddd',
                  marginBottom: 20,
                }}
                src={logo}
                alt=""
              />
            </Box>
            <Typography
              variant="h2"
              sx={{ fontSize: '16px', fontWeight: 800, marginBottom: 1 }}
            >
              Sign In
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: '14px', color: '#606060', fontWeight: 400 }}
            >
              Enter your phone number to continue
            </Typography>
          </Box>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1, textAlign: 'left' }}
          >
            <PhoneNumberInput
              id="phone"
              value={phone}
              onChange={(phone) => {
                setPhone(phone);
                setError(null);
              }}
            />
            {error && (
              <span style={{ color: 'red', fontSize: '14px' }}>{error}</span>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ textTransform: 'none' }}
              sx={{
                mt: 3,
                backgroundColor: '#FF6B15',
                color: '#ffff',
                fontSize: '16px',
                fontWeight: 600,
              }}
            >
              Send OTP
            </Button>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="white"
              style={{
                textTransform: 'none',
                fontSize: '16px',
                color: '#0A0909',
                fontWeight: 500,
              }}
              sx={{ mt: 3 }}
              onClick={() => {
                navigate('/auth', { state: location.state });
              }}
            >
              Use password instead
            </Button>
            <Box sx={{ paddingTop:"20px !important", textAlign: "center" }}>
              <Link href="#" onClick={(e) => { e.preventDefault(); return navigate("/auth", {state: {setLogin: false, redirectTo: location.state}})}} variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const VerifyOTPForm = () => {
    return (
      <Box sx={{ margin: 'auto' }} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            style={{
              width: 48,
              height: 48,
              borderRadius: 99,
              background: '#ddd',
              marginBottom: 20,
            }}
            src={logo}
            alt=""
          />
          <Typography
            variant="h6"
            sx={{
              fontSize: '16px',
              color: '#606060',
              fontWeight: 600,
              textAlign: 'center',
            }}
          >
            OTP sent to {phone}
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleOtp}
            sx={{
              mt: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <OTPInput
              inputStyle="inputStyle"
              value={otp}
              onChange={(str) => {
                setOtp(str);
                setError(null);
              }}
              numInputs={6}
              inputType="tel"
              renderInput={(props) => <input {...props} />}
              shouldAutoFocus
            />
            {error && <span style={{ color: 'red' }}>{error}</span>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: '#FF6B15',
                color: '#ffff',
                fontSize: '16px',
                fontWeight: 600,
              }}
            >
              Sign In
            </Button>
            {error && (
              <Button
                fullWidth
                variant="contained"
                sx={{
                  mt: 1,
                  backgroundColor: '#8a8a8a',
                  color: '#ffff',
                  fontSize: '16px',
                  fontWeight: 600,
                }} // Changed button color
                onClick={() => {
                  handleResendOTP();
                  setOtp(''); // Clear the OTP input box
                }}
              >
                Resend OTP
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const UserInfoForm = () => {
    return (
      <Box sx={{ margin: 'auto' }} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Save Information
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSaveSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
            />
            {error && (
              <span style={{ color: 'red', fontSize: '14px' }}>{error}</span>
            )}
            <Button
              type="submit"
              fullWidth
              style={{ backgroundColor: '#FF6B15', color: '#ffffff' }}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Header title="Sign In" withBackBtn />

      {loading ? (
        <SpinLoader />
      ) : (
        <Container component="main" maxWidth="md">
          <Box
            sx={{
              marginTop: 5,
            }}
          >
            <Grid container>
              <CssBaseline />
              <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                  backgroundRepeat: 'no-repeat',
                  backgroundColor: (t) =>
                    t.palette.mode === 'light'
                      ? t.palette.grey[50]
                      : t.palette.grey[900],
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              ></Grid>
              {login ? PhoneNumberForm() : !isOtpVerified && VerifyOTPForm()}
              {isOtpVerified && openUserInfoForm && <UserInfoForm />}
            </Grid>
          </Box>
        </Container>
      )}
    </>
  );
}
