import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import HomeIcon from '@mui/icons-material/Home';
import SignOutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SearchIcon from '@mui/icons-material/Search';
import CartIcon from '@mui/icons-material/ShoppingCart';
import { Avatar, Chip } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import '../assets/stylesheets/header.scss';
import '../assets/stylesheets/loginbutton.scss';
import { logout } from '../store/reducers/UserReducer';
import SearchBar from './SearchBar';

export default function Header({
  withSearch,
  withCartBtn,
  backBtnNavigateTo, // Workaround for Razorpay's nav back issue after modal close
  withLogo,
  withDrawer,
  withBackBtn,
  title,
  onSearchChange,
  searchValue,
}) {
  const navigate = useNavigate();
  const [search, setSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const userData = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  const list = () => (
    <Box sx={{ width: 250 }} role="presentation">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          p: 1.5,
        }}
      >
        <Avatar size="lg" />

        {userData ? (
          <div>
          <div>
            <Typography
              variant="body4"
              onClick={() => {
                navigate('/profile');
              }}
            >
              {userData.firstname || userData.lastname ? (
                <>
                  {userData.firstname && `${userData.firstname} `}
                  {userData.lastname && `${userData.lastname}`}
                </>
              ) : (
                userData.email || userData.phoneNumber
              )}
            </Typography>
            </div>
            <div>
            <Typography
              variant="caption"
              color={'#606060'}
              style={{ marginTop: 10 }}
              onClick={() => {
                navigate('/profile');
              }}
              
            >
              Manage Account
            </Typography>
          </div>
          </div>
        ) : (
          <Typography
            variant="caption"
            color={'#FF6B15'}
            level="body-sm"
            onClick={() => {
              navigate('/login');
            }}
          >
            Sign In
          </Typography>
        )}
      </Box>
      <Divider />
      <List>
        <ListItem key={'home'} disablePadding>
          <ListItemButton onClick={() => navigate('/')}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItemButton>
        </ListItem>
        <ListItem key={'orders'} disablePadding>
          <ListItemButton onClick={() => navigate('/orders')}>
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary={'Orders'} />
          </ListItemButton>
        </ListItem>

        {userData && (
          <ListItem key={'signout'} disablePadding>
            <ListItemButton
              onClick={() => {
                dispatch(logout());
              }}
            >
              <ListItemIcon>
                <SignOutIcon />
              </ListItemIcon>
              <ListItemText primary={'Sign Out'} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="sticky" color="white">
        <Toolbar style={{ maxWidth: '800px', width: "90%", margin: "auto"}}>
          {search ? (
            <SearchBar
              // value={searchValue}
              onChange={(value) => onSearchChange(value)}
              onClose={() => {
                setSearch(false);
              }}
            />
          ) : (
            <>
              {withBackBtn && (
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="back"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    if (backBtnNavigateTo) {
                      navigate(backBtnNavigateTo);
                      return;
                    }
                    navigate(-1);
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
              {withDrawer && (
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              {withLogo && (
                <div
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: 18,
                    fontWeight: '500',
                    flexGrow: 1,
                  }}
                >
                  R D E R L Y
                </div>
              )}
              {title && (
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {title}
                </Typography>
              )}
              {withCartBtn && cart.products.length > 0 && (
                <Chip
                  variant="contained"
                  icon={<CartIcon />}
                  style={{ background: 'transparent', fontSize: 18 }}
                  label={cart.products ? cart.products.length : 0}
                  clickable
                  onClick={() => {
                    navigate('/cart');
                  }}
                />
              )}
              {withSearch && (
                <IconButton
                  size="large"
                  edge="end"
                  color="inherit"
                  aria-label="search"
                  onClick={() => {
                    setSearch(true);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>

      <Drawer anchor={'left'} open={open} onClose={() => setOpen(false)}>
        {list()}
      </Drawer>
    </>
  );
}
