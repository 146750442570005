import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Container, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Header from '../components/Header';
import PhoneNumberInput from '../components/PhoneNumberInput';
import SpinLoader from '../components/SpinLoader';
import { setProfile } from '../store/reducers/UserReducer';
import {
  useLoginUserMutation,
  useRegisterUserMutation,
} from '../store/services/Auth';
import client from '../store/services/Client';
import { GET_PROFILE_DATA } from '../store/services/Profile';

export default function AuthPage(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [login, setLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [LoginUser, loginResult] = useLoginUserMutation();
  const [RegisterUser, registerResult] = useRegisterUserMutation();
  const navigate = useNavigate();

  useEffect(() => {
    // means coming from OTP login page
    if(location.state?.setLogin !== undefined) {
      setLogin(location.state.setLogin)
    }
  }, [location.state]);

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const login = await LoginUser({
      email: data.get('email'),
      password: data.get('password'),
    });

    const profile = await client.query({
      query: GET_PROFILE_DATA,
    });

    dispatch(setProfile(profile.data.me));
    setLoading(false);
    if (login.data) {
      if(location.state?.setLogin !== undefined) return navigate("/")
      return navigate(location.state || '/');
    }
  };

  const handleRegisterSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);

    const phone = data.get('phone').replace(/\s/g, '');
    
    const register = await RegisterUser({
      email: data.get('email'),
      phone: phone,
      password: data.get('password'),
    });

    const profile = await client.query({
      query: GET_PROFILE_DATA,
    });

    dispatch(setProfile(profile.data.me));
    setLoading(false);
    if (register.data.success) {
      if(location.state?.setLogin !== undefined ) return navigate(location.state.redirectTo || "/")
      return navigate(location.state || '/');
    }
  };

  const Login = () => {
    return (
      <Box sx={{ maxWidth: "600px", margin: "auto"}}  component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            style={{
              width: 48,
              height: 48,
              borderRadius: 99,
              background: '#ddd',
              marginBottom: 20,
            }}
            src="logo512.png"
            alt=""
          />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleLoginSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              style={{ backgroundColor: '#FF6B15', color: '#ffffff' }}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Stack container>
              <div>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </div>
              <div>
                <Link href="#" onClick={() => setLogin(false)} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </div>
            </Stack>
          </Box>
        </Box>
      </Box>
    );
  };

  const SignUp = () => {
    return (
      <Box sx={{margin: "auto"}}  component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            style={{
              width: 48,
              height: 48,
              borderRadius: 99,
              background: '#ddd',
              marginBottom: 20,
            }}
            src="logo512.png"
            alt=""
          />
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleRegisterSubmit}
            sx={{ mt: 1 }}
          >
            <PhoneNumberInput
              id="phone"
              name="phone"
              // value={phone}
              onChange={(phone) => {}}
              style={{marginTop: 20, marginBottom: 8}}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container>
              <Grid item>
                <Link href="#" onClick={() => setLogin(true)} variant="body2">
                  {'Already had an account? Sign In'}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Header title="Sign Up" withBackBtn />
      {loading ? (
        <SpinLoader />
      ) : (
        <Container component="main" maxWidth="md">
          <Box
            sx={{
              marginTop: 5,
            }}
          >
            <Grid container>
              <CssBaseline />
              <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                  // backgroundImage: "url(https://source.unsplash.com/random)",
                  backgroundRepeat: 'no-repeat',
                  backgroundColor: (t) =>
                    t.palette.mode === 'light'
                      ? t.palette.grey[50]
                      : t.palette.grey[900],
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  // alignItems:"center",
                  // justifyContent:"center",
                  // display:"flex"
                }}
              >
                {/* Loyalty Dish */}
              </Grid>
              {login ? <Login /> : <SignUp />}
            </Grid>
          </Box>
        </Container>
      )}
    </>
  );
}
