import { gql } from '@apollo/client';

import { api } from './Api';

export const GET_PROFILE_DATA = gql`
  query {
    me {
      email
      firstname
      lastname
      phoneNumber
      countryDisplay
      city
      zipCode
      street
      picture
      merchantTypeDisplay
      gender
      genderDisplay
      birthday
    }
  }
`;

const UPDATE_PROFILE_DATA = gql`
  mutation (
    $email: String
    $firstname: String
    $lastname: String
    $phoneNumber: String
    $country: String
    $city: String
    $street: String
    $zipCode: String
    $picture: Upload
    $gender: String
    $birthday: Date
  ) {
    updateUser(
      input: {
        email: $email
        firstname: $firstname
        lastname: $lastname
        phoneNumber: $phoneNumber
        country: $country
        city: $city
        street: $street
        zipCode: $zipCode
        picture: $picture
        gender: $gender
        birthday: $birthday
      }
    ) {
      success
      errors
      user {
        email
        firstname
        lastname
        phoneNumber
        countryDisplay
        city
        zipCode
        street
        picture
        merchantTypeDisplay
        gender
        genderDisplay
        birthday
      }
    }
  }
`;

const CHANGE_PASSWORD_MUTATION = gql`
  mutation (
    $oldPassword: String!
    $newPassword1: String!
    $newPassword2: String!
  ) {
    passwordChange(
      oldPassword: $oldPassword
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    ) {
      success
      errors
      token
      refreshToken
    }
  }
`;

const ProfileApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    GetProfile: build.query({
      query: (variables) => ({ document: GET_PROFILE_DATA, variables }),
      transformResponse: (response) => response.me,
      providesTags: ['Profile'],
    }),
    UpdateProfile: build.mutation({
      query: (variables) => ({ document: UPDATE_PROFILE_DATA, variables }),
      invalidatesTags: ['Profile'],
    }),
  }),
});

export { ProfileApi as api };
export const { useGetProfileQuery, useUpdateProfileMutation } = ProfileApi;
