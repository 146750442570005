import React from 'react';

function SpinLoader() {
    return (
        <div className="loader-container">
            <div className="loader"></div>
            <p>Loading...</p>
        </div>
    );
}

export default SpinLoader;
