import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton } from '@mui/material';

import ArrowUp from '../assets/icons/arrow-top.svg';

export default function CartItem(props) {
  const { onAddQuantity, onReduceQuantity, handleClickOpen, item } = props;
  const {
    name,
    description,
    id,
    pictures,
    price,
    priceAmount,
    quantity,
    productComponentsGroups,
    total,
    subTotal,
    subTotal2,
  } = props.item;
  let extra = productComponentsGroups.edges.length ? true : false;
  let optional = Object.keys(item.optional);
  let required = Object.keys(item.required);

  if (props.orderDetail) {
    return (
      <div className="cart-item-container">
        <div className="first" >
          <img src={ArrowUp} style={{ width: '16px', height: '16px' }} />
          <div>
            <p className="name">{name}</p>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <div className="quantity">{quantity} </div>
              <p className="price">X</p>
              <p className="price">₹{priceAmount}</p>
            </div>
            <p className="description">{description}</p>
            {required.map((key, index) => {
              return (
                <p key={index} className="description">
                  *{item.required[key].shortDescription}
                </p>
              );
            })}
            {optional.map((options) => {
              return item.optional[options].map((key, index) => {
                return (
                  <p key={index} className="description">
                    *{key.shortDescription}
                  </p>
                );
              });
            })}
          </div>
        </div>
        <div className="second" style={{ alignSelf: 'flex-end' }}>
          <div className="price">
            ₹{((total + subTotal + subTotal2) * quantity).toFixed(2)}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="cart-item-container">
      <div className="first">
        <img src={ArrowUp} />
        <div>
          <p className="name">{name}</p>
          <p className="price">₹{priceAmount}</p>
          <p className="description">{description}</p>
          {required.map((key, index) => {
            return (
              <p key={index} className="description">
                *{item.required[key].shortDescription}
              </p>
            );
          })}
          {optional.map((options) => {
            return item.optional[options].map((key, index) => {
              return (
                <p key={index} className="description">
                  *{key.shortDescription}
                </p>
              );
            });
          })}
          {extra && (
            <div
              onClick={() => handleClickOpen(props.item)}
              className="customize-container"
            >
              <p className="customize">Customize</p>
              <KeyboardArrowDownIcon
                style={{ cursor: 'pointer', color: '#785EF0' }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="second">
        <div className="qty-btn">
          <IconButton size="small" edge="end" onClick={onReduceQuantity}>
            <RemoveIcon />
          </IconButton>
          <div>{quantity}</div>
          <IconButton size="small" edge="end" onClick={onAddQuantity}>
            <AddIcon />
          </IconButton>
        </div>
        <div className="price">
          ₹{((total + subTotal + subTotal2) * quantity).toFixed(2)}
        </div>
      </div>
    </div>
  );
}
