import React, { useState, useMemo, useEffect } from 'react';
import { useTransition, useSpringRef, animated, config } from 'react-spring';

const Counter = ({ isExist, onAddPress, dir }) => {
    const transRef = useSpringRef();
    const transitions = useTransition(isExist, {
        ref: transRef,
        keys: isExist,
        from: { transform: `translateY(${dir === 1 ? 100 : -100}%)` },
        enter: { transform: `translateY(0%)` },
        leave: { transform: `translateY(${dir === 1 ? -100 : 100}%)` },
        config: { duration: 100 }
    });

    useEffect(() => {
        transRef.start();
    }, [isExist]);

    return (
        <div className="btn2">
            {transitions((styles, i) => (
                <button onClick={isExist ? null : onAddPress} key={i} className={`btn second-digit`}>
                    <animated.div style={styles} className="btn-txt">{isExist ? isExist : "Add"}</animated.div>
                </button>
            ))}
        </div>
    );
};

export default Counter;

