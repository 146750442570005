import React, { useRef } from 'react';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PaymentIcon from '@mui/icons-material/Payment';
import { useDispatch } from 'react-redux';
import { setActivePaymentGateway } from '../../store/reducers/CartReducer';


function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve();
    script.onerror = () => reject();
    document.body.appendChild(script);
  });
}

const Razorpay = ({
  settings,
  gateway,
  onPaymentGatewayParamsResolved,
  cart,
  onLoading,
}) => {
  const rzp = useRef(null);
  const dispatch = useDispatch();
  
  const onClick = async () => {
    dispatch(setActivePaymentGateway(gateway.pk))
    await loadScript('https://checkout.razorpay.com/v1/checkout.js').then(() => {
      rzp.current = window.Razorpay({
        key: JSON.parse(settings).api_key,
        name: cart.store.slug,
        description: cart.store.slug,
        image: 'https://i.imgur.com/3g7nmJC.png',
        currency: 'INR',
        amount: Math.round(cart.total * 100),
        prefill: {
          contact: cart.phoneNumber,
        },
        handler: onPaymentGatewayParamsResolved,
        modal: {
          ondismiss: () => {
            onLoading(false);
          },
        },
      });
    });
    if (rzp.current) {
      onLoading(true);
      rzp.current.open();
    }
  };

  return (
    <div
      onClick={onClick}
      style={{
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        height: 50,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: 10,
        paddingLeft: 10,
        margin: '5px 15px 5px 15px',
        cursor: 'pointer',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <PaymentIcon />
        <p style={{ fontFamily: 'Poppins', marginLeft: 10, fontSize: 16 }}>
          Pay Using RazorPay
        </p>
      </div>
      <KeyboardArrowRightIcon />
    </div>
  );
};

export default Razorpay;
