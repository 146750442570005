import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { animated, useSpring } from 'react-spring';

// Using react-spring for animations
import { setOrderType } from '../store/reducers/CartReducer';

const PickupDeliverySwitch = () => {
  const [isDelivery, setIsDelivery] = useState(false);
  const dispatch = useDispatch();

  // Define spring animation for the switch
  const switchAnimation = useSpring({
    left: isDelivery ? '49.5%' : '0.5%',
  });

  useMemo(() => {
    if (isDelivery) {
      dispatch(setOrderType('ONLINE_PICKUP'));
    } else {
      dispatch(setOrderType('IN_STORE'));
    }
  }, [isDelivery]);

  return (
    <div className="pickup-delivery-switch">
      <div className="switch-container">
        <animated.div className="switch" style={switchAnimation} />
        <div className="switch-labels">
          <span
            className={!isDelivery ? 'active' : ''}
            onClick={() => setIsDelivery(false)}
          >
            Dine In
          </span>
          <span
            className={isDelivery ? 'active' : ''}
            onClick={() => setIsDelivery(true)}
          >
            Pickup
          </span>
        </div>
      </div>
    </div>
  );
};

export default PickupDeliverySwitch;
