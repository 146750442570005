import React, { useState } from 'react';
import Sheet from 'react-modal-sheet';

import CloseCircle from '../assets/icons/close-circle.svg';

export default function MenuButton(props) {
  const [isOpen, setOpen] = useState(false);
  const [active, setActive] = useState({ pk: 0, name: 'All' });
  const { categories } = props;

  const onPress = (category) => {
    props.categoryFilter(category.name);
    setActive(category);
    setOpen(false);
  };

  const renderItem = (category, idx) => {
    return (
      <a
        href={`#${category.name}`}
        onClick={() => onPress(category)}
        className={`sheet-content-container ${active.pk === category.pk ? 'active' : ''}`}
        key={idx}
        style={{ textDecoration: "none" }}
      >
        <p className="title">
          {category.name}{' '}
          {category.name !== 'All' && `(${category.products.count})`}
        </p>
      </a>
    );
  };
  return (
    <>
      <div onClick={() => setOpen(true)} className="menu-button">
        <p>🍝 {active.name === 'All' ? 'Menu' : active.name}</p>
      </div>

      <Sheet
        className="sheet-container"
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      >
        <Sheet.Container>
          <Sheet.Header>
            <div className="sheet-header">
              <div>
                <p className="title">Full Menu</p>
                {/* <p className="description">11:00 AM to 9:30 PM</p> */}
              </div>
              <img onClick={() => setOpen(false)} className='close-image' alt='close'  src={CloseCircle} />
            </div>
          </Sheet.Header>
          <Sheet.Content>
            <Sheet.Scroller>
              {categories
                .filter((cat) => {
                  if (cat.name === 'All') {
                    return cat;
                  }
                  return cat.products.count > 0;
                })
                .map((category, idx) => renderItem(category, idx))}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  );
}
