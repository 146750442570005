import AddIcon from '@mui/icons-material/Add';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RemoveIcon from '@mui/icons-material/Remove';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import * as React from 'react';
import CloseCircle from "../assets/icons/close-circle.svg";
import { containsObject, currencyToNumber, keyGenerator } from '../helpers/utils';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddItem(props) {
    const { open, handleClickOpen, handleClose, } = props;
    const [product, setProduct] = React.useState({})
    const [extra, setExtra] = React.useState([])
    const [optional, setOptional] = React.useState({})
    const [required, setRequired] = React.useState({})
    const [total, setTotal] = React.useState(0)
    const [subTotal, setSubTotal] = React.useState(0)
    const [subTotal2, setSubTotal2] = React.useState(0)
    const [extraInfo, setExtraInfo] = React.useState("")
    const [quantity, setQuantity] = React.useState(1)
    const [key, setKey] = React.useState(null);
    const [newComponent, setNewComponent] = React.useState([]);
    const [deleteComponent, setDeleteComponent] = React.useState([]);
    const [oldRequired, setOldRequired] = React.useState({});
    const [oldOptional, setOldOptional] = React.useState({});
    const [guest, setGuest] = React.useState(0);

    React.useEffect(() => {
        console.log(props.product)
        if (props.product) {
            if (props.product.key) {
                const { optional, required, quantity, total, subTotal, subTotal2, extraInfo } = props.product
                let componentsArray = props.product.productComponentsGroups.edges.map(edge => edge.node)
                componentsArray.sort(function (a, b) { return a.isRequired - b.isRequired });
                setProduct(props.product)
                setKey(props.product.key)
                setExtra(componentsArray.reverse())
                setOptional(optional)
                setRequired(required)
                setOldRequired(required)
                setOldOptional(optional)
                setNewComponent(props.product.newComponent ? props.product.newComponent : [])
                setDeleteComponent(props.product.deleteComponent ? props.product.deleteComponent : [])
                setTotal(total)
                setSubTotal(subTotal)
                setQuantity(quantity)
                setSubTotal2(subTotal2)
                setExtraInfo(extraInfo)
            }
            else {
                let componentsArray = props.product.productComponentsGroups.edges.map(edge => edge.node)
                componentsArray.sort(function (a, b) { return a.isRequired - b.isRequired });
                setProduct(props.product)
                setExtra(componentsArray.reverse())
                setTotal(currencyToNumber(props.product.price))
                setQuantity(1)
                setOptional({})
                setRequired({})
                setOldRequired({})
                setOldOptional({})
                setNewComponent([])
                setDeleteComponent([])
                setSubTotal(0)
                setSubTotal2(0)
                setKey(null)
            }
        }
    }, [props.product, open])

    const onSelectOptional = (item, name) => {
        let dd = { ...optional }
        const oldDd = { ...oldOptional }
        const result = deleteComponent.filter(function (obj) {
            return obj.id !== item.id;
        });
        setDeleteComponent(result)
        if (oldDd[name]) {
            if (oldDd[name].some(e => e.id === item.id)) {
                /* vendors contains the element we're looking for */

            }
            else {
                setNewComponent(prevState => {
                    return [...prevState, item]
                })
            }
        }
        else {
            setNewComponent(prevState => {
                return [...prevState, item]
            })
        }
        if (dd[name]) {
            dd[name] = [...dd[name],item]
        }
        else {
            dd[name] = [item]
        }
        setOptional(prevState => {
            return { ...prevState, ...dd };
        })
        setSubTotal(subTotal + currencyToNumber(item.price))
    }

    const onRemoveOptional = (item, name) => {
        let dd = {...optional}
        const oldDd = {...oldOptional}
        if (oldDd[name]) {
            if (oldDd[name].some(e => e.id === item.id)) {
                /* vendors contains the element we're looking for */
                let result = oldDd[name].find(obj => {
                    return obj.id === item.id
                })
                setDeleteComponent(prevState => {
                    return [...prevState, result]
                })
            }
        }
        const result = dd[name].filter(({ id }) => !id.includes(item.id))
        dd[name] = result
        setOptional(prevState => {
            return { ...prevState, ...dd };
        })
        setSubTotal(subTotal - currencyToNumber(item.price))
    }
    const selectRequired = (item, name) => {
        const dd = { ...required }
        const oldDd = { ...oldRequired }
        if (oldDd[name]?.id === item.id) {
            setDeleteComponent(prevState => {
                return [...prevState, oldDd[name]]
            })
        }
        else {
            setNewComponent(prevState => {
                return [...prevState, item]
            })
        }
        dd[name] = item
        setRequired(prevState => {
            return { ...prevState, ...dd };
        })
        setSubTotal2(currencyToNumber(item.price))

    }

    const quantityModifier = (value) => {

        if (value) {
            setQuantity(quantity + 1)
        }
        else {
            setQuantity(quantity - 1)
        }

    }

    const addProductToCart = () => {
        let extraData = {
            quantity,
            optional,
            required,
            total,
            subTotal,
            subTotal2,
            extraInfo,
            guest,
            newComponent,
            deleteComponent,
            key: key ? key : keyGenerator()
        }
        props.addProducts({ ...product, ...extraData })
        handleClose()
    }

    const renderOptional = (item, index) => {
        const extra = item.components.edges.map(edge => edge.node)
        const { name } = item
        return (
            <div className='extra-container' key={index}>
                <div className='second-header'>
                    <div className='title'>{name}</div>
                    <div className='extra-tag'>
                        optional
                    </div>
                </div>
                {extra.map((item, idx) => renderOptionalItem(item, name, idx))}
            </div>
        )
    }

    const renderOptionalItem = (item, name, idx) => {
        const selected = optional[name] && containsObject(item, optional[name])
        return (
            <div onClick={() => selected ? onRemoveOptional(item, name) : onSelectOptional(item, name)} className='extra-item-container' key={idx}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", color: "#0A0909" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {selected ? <CheckBoxIcon color='#0A0909' style={{ marginRight: '8px' }} /> : <CheckBoxOutlineBlankIcon color='#0A0909' style={{ marginRight: '8px' }} />}
                  <div className='title'>{item.shortDescription}</div>
                </div>
                <div className='price' style={{ position: 'absolute', right: 0 }}>{item.price}</div>
              </div>
            </div>
          )
          
    }

    const renderRequired = (item, index) => {
        const extra = item.components.edges.map(edge => edge.node)
        const { name } = item
        return (
            <div className='extra-container' key={index}>
                <div className='second-header'>
                    <div className='title'>{name}</div>
                    <div className='extra-tag'>
                        Required
                    </div>
                </div>
                {extra.map((item, idx) => renderRequiredItem(item, name, idx))}
            </div>
        )
    }

    const renderRequiredItem = (item, name, index) => {
        const dd = required
        if (!dd[name]) {
            dd[name] = item
            setRequired(prevState => {
                return { ...prevState, ...dd };
            })
            setSubTotal2(currencyToNumber(item.price))
        }
        const selected = required[name] ? required[name].id === item.id : false
        return (
            <div onClick={() => selectRequired(item, name)} className='extra-item-container' key={index}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", color: "#0A0909" }}>
                    {selected ? <RadioButtonCheckedIcon color='#0A0909' style={{ marginRight: '8px' }} /> : <RadioButtonUncheckedIcon color='#0A0909' style={{ marginRight: '8px' }} />}
                    <div className='title'>{item.shortDescription}</div>
                </div>
                {/* <div className='price'>$120</div> */}
            </div>
        )
    }

    const renderExtraData = (item, index) => {
        if (item.isRequired) {
            return renderRequired(item, index)
        }
        else {
            return renderOptional(item, index)
        }
    }
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <div className='add-item-container'>
                <div className='header'>
                    <div>
                        <div className='title'>{product.name}</div>
                        <div className='desc'>{product.shortDescription}</div>
                    </div>
                    <img onClick={handleClose} className='close-image' src={CloseCircle} />
                </div>
                <div className='content'>
                    {extra.map((item, index) => {
                        return renderExtraData(item, index)
                    })}
                </div>
                <div className='footer'>
                    <div className='first'>
                        <button onClick={() => quantityModifier(false)} disabled={quantity === 1} className='plus'><RemoveIcon style={{ padding: 5, cursor: "pointer", opacity: quantity === 1 ? 0.5 : 1 }} /></button>
                        <div className='quantity'>{quantity}</div>
                        <button onClick={() => quantityModifier(true)} className='minus'><AddIcon style={{ padding: 5, cursor: "pointer" }} /></button>
                    </div>
                    <div onClick={addProductToCart} className='second'>
                        <div>add to order: ₹{((total + subTotal + subTotal2) * quantity).toFixed(2)}</div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}