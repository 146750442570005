import React, { useState } from 'react';
import Sheet from 'react-modal-sheet';

function AlertModal({ isOpen, setIsOpen, handleYes, handleNo, store, item }) {

    return (
        <Sheet isOpen={isOpen} onClose={() => setIsOpen(false)} snapPoints={[]}>
            <Sheet.Container>
                {/* <Sheet.Header /> */}
                <Sheet.Content>
                    <div style={{ padding: 10 }}>
                        <div className='primary strong'>Replace items already in the cart</div>
                        <div className='primary'>
                            Your cart contains dishes from {store?.name}. Do you want to discard the selection
                            and add dishes from {item?.stores?.edges[0]?.node?.name}?
                        </div>
                        <div className='button-container'>
                            <div className='secondary-button' onClick={handleNo}>
                                NO
                            </div>
                            <div className='primary-button' onClick={handleYes}>
                                YES
                            </div>
                        </div>
                    </div>
                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
        </Sheet>
    );
}

export default AlertModal;
