import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import CallIcon from '@mui/icons-material/Call';
import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import CartItem from '../components/CartItem';
import Header from '../components/Header';
import Invoice from '../components/Invoice';
import SpinLoader from '../components/SpinLoader';
import { calculateQuantitySum, getDateString } from '../helpers/utils';
import { useGetOrderQuery } from '../store/services/OrderApi';

function OrderDetail() {
  const [openCall, setOpenCall] = useState(false);
  const cart = useSelector((state) => state.cart);
  const { id } = useParams();
  const { data: order, isLoading } = useGetOrderQuery({ id: id });
  
  return (
    <div>
      <Header title={`Order #${id}`} withBackBtn />
      {isLoading ? (
        <SpinLoader />
      ) : order ? (
        <div className="order-detail-container">
          <div
            style={{
              margin: '10px 0px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                height: 72,
                width: 72,
                borderRadius: 36,
                background: '#ddd',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '8px',
              }}
            >
              <img
                src={order?.store?.logo}
                style={{ height: 48, width: 48, objectFit: 'cover' }}
              />
            </div>
            <div className="title">{order?.store?.name}</div>
            <div className="caption">{order?.store?.address}</div>
          </div>
          <Divider />
          <div className="title center strong">Your Order</div>
          <div className="items-container">
            {order.products.map((item) => (
              <CartItem
                orderDetail
                isExist={calculateQuantitySum(order.products, item.id)}
                item={item}
              />
            ))}
          </div>
          <Invoice products={order?.products} store={order.store} discount={ order.discount ? Number((order.discounted).replace("\u20b9","")): 0} />
          <div className="title center">Order Details</div>
          <div className="items-container">
            <div style={{ marginBottom: 10 }}>
              <div className="caption strong">Order Number</div>
              <div className="caption"># {id} </div>
            </div>
            <div style={{ marginBottom: 10 }}>
              <div className="caption strong">Payment</div>
              <div className="caption">Paid: Cash</div>
            </div>
            <div style={{ marginBottom: 10 }}>
              <div className="caption strong">Date</div>
              <div className="caption">
                {getDateString(new Date(order.createdAt))}
              </div>
            </div>
            <div style={{ marginBottom: 10 }}>
              <div className="caption strong">Phone Number</div>
              <div className="caption">{order.consumer.phoneNumber}</div>
            </div>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={() => {
                setOpenCall(true);
              }}
              style={{ marginTop: 40 }}
            >
              Contact Store
            </Button>
          </div>

          <Drawer
            anchor="bottom"
            open={openCall}
            onClose={() => setOpenCall(false)}
          >
            <Box onClick={() => {}} onKeyDown={() => {}}>
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      window.open(`tel:${order?.store?.phoneNumber}`);
                    }}
                  >
                    <ListItemIcon>
                      <CallIcon />
                    </ListItemIcon>
                    <ListItemText primary={order?.store?.phoneNumberDisplay} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </div>
      ) : null}
    </div>
  );
}

export default OrderDetail;
