import React from 'react';
import Sheet from 'react-modal-sheet';

const ErrorModal = ({ isOpen, setIsOpen }) => {
  return (
    <Sheet isOpen={isOpen} onClose={() => setIsOpen(false)} snapPoints={[]}>
      <Sheet.Container>
        {/* <Sheet.Header /> */}
        <Sheet.Content>
          <div style={{ padding: 40 }}>
            This item is not available at the moment. Please try again later.
            <div className="button-container">
              <div className="primary-button" onClick={()=>setIsOpen(false)}>
                OK
              </div>
            </div>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

export default ErrorModal;
