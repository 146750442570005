import { useMutation, useQuery } from '@apollo/client';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import { gsap } from 'gsap/dist/gsap';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Alert, Box, Typography } from '@mui/material';

import AddItem from '../components/AddItem';
import AlertModal from '../components/AlertModal';
import ErrorModal from '../components/ErrorModal';
import Header from '../components/Header';
import ItemCard from '../components/ItemCard';
import MenuButton from '../components/MenuButton';
import OfferSlider from '../components/OfferSlider';
import PlaceOrderButton from '../components/PlaceOrderButton';
import { addItemToDb, calculateQuantitySum, removeItemFromDb, updateItemToDb } from '../helpers/utils';
import {
  addButton,
  addItem,
  addQuantityByButton,
  emptyCart,
  reduceQuantityByButton,
  setStore,
  setTable
} from '../store/reducers/CartReducer';
import { ADD_ITEM, GET_ORDER, REMOVE_ITEM, UPDATE_ITEM } from '../store/services/OrderApi';
import {
  useGetProductsCategoriesQuery,
  useGetProductsQuery,
} from '../store/services/ProductsApi';
import { useGetStoreQuery } from '../store/services/StoreApi';

gsap.registerPlugin(ScrollToPlugin);

export default function RestaurantPage() {
  const { slug } = useParams();
  const [params, setParams] = useSearchParams();
  const [open, setOpen] = React.useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [product, setProduct] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  // const [currPage, setCurrPage] = useState(1);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  // const [perPage, setPerPage] = useState(20);
  // const [category, setCategory] = useState(null);

  // New state for grouped products
  const [groupedProducts, setGroupedProducts] = useState({});
  // Ref for category scrolling
  const categoryRefs = useRef({});

  const { data, isSuccess, isLoading } = useGetProductsQuery({
    slug: slug,
    // first: perPage,
    // offset: perPage * (currPage - 1),
    // category: category,
    // name: debouncedSearchTerm,
  });

  const cart = useSelector((state) => state.cart);
  const { data: store } = useGetStoreQuery(slug);
  const { data: categories, isLoading: categoryLoading } =
    useGetProductsCategoriesQuery({ storeSlug: slug });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [addItemMutation] = useMutation(ADD_ITEM);
  const [updateItem] = useMutation(UPDATE_ITEM);
  const [removeItemDb] = useMutation(REMOVE_ITEM)
  const res = useQuery(GET_ORDER, {
    variables: { id: cart.order?.id },
    skip: !cart.order?.id,
  });

  const onAddPress = async (item) => {
    setCurrentItem(item);
    // console.log("CART: ", cart)
    if (cart.store && cart.products) {
      if (checkStore(item)) {
        if (checkItem(item)) {
          dispatch(addButton(item));
          console.log("onAddPress called")
          try {
            await addItemToDb(
              item,
              cart,
              res?.data?.order.id,
              addItemMutation,
              res,
              dispatch,
              setError,
            );
          } catch (error) {
            setError(
              'Error updating the item quantity. Please try again later.',
            );
            dispatch(reduceQuantityByButton(item));
          }
        } else {
          // console.log(item);
          setErrorModalOpen(true);
        }
      } else {
        setAlertModalOpen(true);
      }
    } else {
      dispatch(addButton(item));
      dispatch(setStore(store));
    }
  };

  const onAddItem = async (item) => {
    console.log('onAddItem Called');
    setCurrentItem(item);
    if (cart.store && cart.products) {
      if (checkStore(item)) {
        if (checkItem(item)) {
          dispatch(addItem(item));
          try {
            await addItemToDb(
              item,
              cart,
              res?.data?.order.id,
              addItemMutation,
              res,
              dispatch,
              setError,
            );
          } catch (error) {
            // setError(
            //   'Error adding this item. Please try again later.',
            // );
            // dispatch(reduceQuantityByButton(item));
          }
        } else {
          setErrorModalOpen(true);
        }
      } else {
        setAlertModalOpen(true);
      }
    } else {
      dispatch(addItem(item));
      dispatch(setStore(store));
    }
  };

  const onAddQuantity = async (item) => {
    dispatch(addQuantityByButton(item));
    try {
      await updateItemToDb(
        item,
        cart,
        res?.data?.order,
        updateItem,
        true,
        res,
        dispatch,
        setError,
      );
    } catch (error) {
      setError('Error updating the item quantity. Please try again later.');
      dispatch(reduceQuantityByButton(item));
    }
  };

  const onReduceQuantity = async (itemId) => {
    try {
      console.log("onReduceQuantity called: ")
      dispatch(reduceQuantityByButton(itemId));
      const item = cart.products.find((item) => item.id === itemId);
      if (item.quantity === 1) {
        await removeItemFromDb(item, cart, res?.data?.order, removeItemDb, res, dispatch, setError);
        return;
      }
      await updateItemToDb(itemId, cart, res?.data?.order, updateItem, false, res, dispatch, setError);
    } catch (error) {
      setError("Error updating the item quantity. Please try again later.")
      dispatch(addQuantityByButton(itemId));
    }
  };


  const handleClickOpen = (item) => {
    setOpen(true);
    setProduct(item);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = async () => {
    await dispatch(emptyCart());
    dispatch(addButton(currentItem));
    dispatch(setStore(store));
    setAlertModalOpen(false);
  };

  const handleNo = () => {
    setAlertModalOpen(false);
  };

  const checkStore = (item) => {
    return item.stores.edges[0].node.pk === cart.store.pk;
  };

  const checkItem = (item) => {
    return !item.outOfStock;
  };

  // Modify renderItems to render grouped products
  const renderItems = () => {
    return Object.entries(groupedProducts).map(([category, products], idx) => (
      <Box key={idx} sx={{ paddingInline: '10px' }} id={category}>
        <Typography
          variant="h5"
          ref={(el) => (categoryRefs.current[category] = el)}
          style={{
            marginBottom: '16px',
            marginTop: '24px',
            fontWeight: '500',
            textTransform: 'capitalize',
            fontFamily: 'Poppins, sans-serif',
            color: '#333333',
            fontSize: '1.7rem',
          }}
        >
          {category.toLowerCase()}
        </Typography>
        {products.map((item, i) => (
          <ItemCard
            handleClickOpen={handleClickOpen}
            isExist={calculateQuantitySum(cart.products, item.id)}
            onAddQuantity={() => onAddQuantity(item.id)}
            onReduceQuantity={() => onReduceQuantity(item.id)}
            onAddPress={() => onAddPress(item)}
            checkItem={checkItem}
            item={item}
            key={i}
          />
        ))}
      </Box>
    ));
  };

  useEffect(() => {
    if (params.get('table')) {
      dispatch(setTable(params.get('table')));
    }
  }, [params]);

  // Update groupedProducts whenever data changes
  useEffect(() => {
    if (data && data.products) {
      const grouped = data.products.reduce((acc, product) => {
        const category = product.category.name;
        // Check if the product already exists in the grouped object
        if (!acc[category] || !acc[category].find((p) => p.id === product.id)) {
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(product);
        }
        return acc;
      }, {});

      setGroupedProducts(grouped);
    }
  }, [data]);

  const searchText = (_text, searchedByMenu = false) => {
    if (searchedByMenu) {
      // Add logic to scroll to category if _text is a category name
      if (Object.keys(groupedProducts).includes(_text)) {
        // setGroupedProducts(groupedProducts);
        // scrollToCategory(_text);
      }
      return;
    }

    let text = _text.toLowerCase();
    let products = data.products;
    let filteredProduct = products.filter((item) => {
      return (
        item.name.toLowerCase().match(text) ||
        item.id.toLowerCase().match(text) ||
        item.category.name.toLowerCase().match(text)
      );
    });

    const grouped = filteredProduct.reduce((acc, product) => {
      const category = product.category.name;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(product);
      return acc;
    }, {});

    if (grouped) {
      window.scrollTo({ top: 500 });
      setGroupedProducts(grouped);
    }

    if (!text || text === '') {
      setFilteredProducts(null);
    } else if (!Array.isArray(filteredProduct) && !filteredProduct.length) {
      setFilteredProducts([]);
    } else if (Array.isArray(filteredProduct)) {
      setFilteredProducts(filteredProduct);
    }
  };

  return (
    <div>
      <AlertModal
        isOpen={alertModalOpen}
        setIsOpen={setAlertModalOpen}
        handleYes={handleYes}
        handleNo={handleNo}
        store={cart.store}
        item={currentItem}
      />

      <ErrorModal isOpen={errorModalOpen} setIsOpen={setErrorModalOpen} />

      <Header
        search
        onSearchChange={searchText}
        // searchValue={searchTerm}
        title={store?.name}
        withSearch
        withBackBtn
        withCartBtn
        backBtnNavigateTo="/"
      />
      {
        error && (
          <Alert severity="error" variant='filled' >
            {error}
          </Alert>
        )
      }
      <div
        style={{
          maxWidth: '900px',
          margin: 'auto',
        }}
      >
        <div
          style={{
            margin: '10px 0px',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#fdfdfd',
            borderBottom: '1px solid #eee',
          }}
        >
          <div
            style={{
              height: 72,
              width: 72,
              borderRadius: 36,
              background: '#ddd',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '16px',
              marginTop: '16px',
            }}
          >
            <img
              src={store?.logo}
              style={{ height: 48, width: 48, objectFit: 'cover' }}
              alt="Store"
            />
          </div>
          <Typography variant="h6">{store?.name}</Typography>
          <Typography variant="caption" style={{ textAlign: 'center' }}>
            {store?.address}
          </Typography>
          {/* <OfferComponent /> */}

          <OfferSlider stores_Slug={slug} />
        </div>

        {renderItems()}
        {/* <div style={{ margin: '24px 16px 16px' }}>
        {isLoading ? (
          <>
            {((data && data.pageInfo.hasNextPage) || currPage === 1) &&
              [...Array(4)].map((u, i) => i).map((_) => <Loader key={_} />)}
          </>
        ) : (
          <>
            {renderItems()}
            {((data && data.pageInfo.hasNextPage) || currPage === 1) && (
              <InView
                as="div"
                onChange={(inView, entry) => {
                  if (!isLoading && inView && data.products.length > 0) {
                    setCurrPage((curr) => curr + 1);
                  }
                }}
              >
                <Loader />
              </InView>
            )}
          </>
        )}
      </div> */}

        {!categoryLoading && (
          <MenuButton
            categories={categories.slice(1)}
            categoryFilter={(category) => {
              searchText(category, true);
            }}
          />
        )}

        <PlaceOrderButton onPress={() => navigate(`/cart`)} />

        <AddItem
          open={open}
          handleClose={handleClose}
          product={product}
          addProducts={onAddItem}
        />
      </div>
    </div>
  );
}
