import { gql } from '@apollo/client';

import { api } from './Api';

export const SignUp = gql`
  mutation SignUp(
    $email: String!
    $password: String!
    $firstname: String
    $lastname: String
    $userType: String!
    $merchantType: String
    $phoneNumber: String
    $birthday: String
    $country: String
    $city: String
    $street: String
    $zipCode: String
    $gender: String
  ) {
    register(
      email: $email
      password1: $password
      password2: $password
      firstname: $firstname
      lastname: $lastname
      userType: $userType
      merchantType: $merchantType
      phoneNumber: $phoneNumber
      birthday: $birthday
      country: $country
      city: $city
      street: $street
      zipCode: $zipCode
      gender: $gender
    ) {
      success
      errors
      token
      refreshToken
    }
  }
`;

export const Login = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      success
      errors
      unarchiving
      refreshToken
    }
  }
`;

const generateOtp = gql`
  mutation GenerateOTP($phoneNumber: String!) {
    generateOtp(input: { phoneNumber: $phoneNumber, userType: "CONSUMER" }) {
      success
      errors
      sessionId
    }
  }
`;

const verifyOtp = gql`
  mutation VerifyOTP($sessionId: String!, $otp: String!) {
    verifyOtp(input: { sessionId: $sessionId, otp: $otp }) {
      accessToken
      refreshToken
      token
      success
      errors
    }
  }
`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === 'apply',
  endpoints: (build) => ({
    RegisterUser: build.mutation({
      query: (variables) => ({
        document: SignUp,
        variables: { ...variables, userType: 'CONSUMER' },
      }),
      transformResponse: (response) => response.register,
    }),
    LoginUser: build.mutation({
      query: (variables) => ({ document: Login, variables }),
      transformResponse: (response) => response.tokenAuth,
    }),
    GenerateOtp: build.mutation({
      query: (variables) => ({ document: generateOtp, variables }),
      transformResponse: (response) => response.generateOtp,
    }),
    VerifyOtp: build.mutation({
      query: (variables) => ({ document: verifyOtp, variables }),
      transformResponse: (response) => response.verifyOtp,
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useGenerateOtpMutation,
  useVerifyOtpMutation,
} = injectedRtkApi;
