import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children }) => {
    const isAuthenticated = useSelector(state => state.auth.isLoggedIn);

    if (isAuthenticated) {
        return children
    }
    return <Navigate to="/login" replace />
};

export default ProtectedRoute;