import { useQuery } from '@apollo/client';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { ArrowBack, ArrowForward } from '@mui/icons-material';

import { GET_OFFERS_QUERY } from '../store/services/OffersApi';
import MyLoader from './Loader';

const OfferSlider = ({ stores_Slug }) => {
  const { loading, error, data } = useQuery(GET_OFFERS_QUERY, {
    variables: { stores_Slug },
  });

  if (loading) return <MyLoader />;
  if (error) return <p>Error</p>;

  // Safely access the edges property using optional chaining and nullish coalescing
   const pictures = data?.offers?.edges[0]?.node?.pictures?.edges?.map(edge => edge.node.url) ?? [];

  // Check if there are no pictures or if there's an error
  if (!pictures.length || error) {
    return null; // or return <p>No pictures available</p>;
  }

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group">
        <button
          className={currentSlide === 0 ? 'disabled left' : 'left'}
          onClick={() => previous()}
        >
          <ArrowBack />
        </button>
        <button onClick={() => next()} className="right">
          <ArrowForward />
        </button>
      </div>
    );
  };

  return (
    <>
      <div className='offer_wrapper_small'>
        {pictures.length <= 3 &&
          pictures.map((url, index) => (
            <img key={index} src={url} alt="Offer" />
          ))}
      </div>
      {pictures.length > 3 && (
        <div className="offer-wrapper">
          <Carousel
            responsive={responsive}
            customButtonGroup={<ButtonGroup />}
            renderButtonGroupOutside
            arrows={false}
          >
            {pictures.map((url, index) => (
              <img key={index} src={url} alt="Offer" />
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default OfferSlider;
