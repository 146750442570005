import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { api } from '../services/Auth';
import { api as ProfileApi } from '../services/Profile';

const initialState = {
  isLoading: false,
  isLoggedIn: false,
  accessToken: null,
  token: null,
  refreshToken: null,
  user: null,
  location: null,
  open: false,
};

const AuthReducer = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logout: () => {
      return initialState;
    },
    setLocation: (state, payload) => {
      return { ...state, location: payload.payload };
    },
    setDrawer: (state, payload) => {
      return { ...state, open: !state.open };
    },
    setProfile: (state, { payload }) => {
      return { ...state, user: payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        api.endpoints.VerifyOtp.matchFulfilled,
        (state, { payload }) => {
          let newState = {
            ...state,
            ...payload,
            accessToken: payload.token,
            isLoading: false,
            isLoggedIn: true,
          };
          return newState;
        },
      )
      .addMatcher(
        api.endpoints.LoginUser.matchFulfilled,
        (state, { payload }) => {
          let newState = {
            ...state,
            ...payload,
            accessToken: payload.token,
            isLoading: false,
            isLoggedIn: true,
          };
          return newState;
        },
      )
      .addMatcher(
        api.endpoints.RegisterUser.matchFulfilled,
        (state, { payload }) => {
          let newState = {
            ...state,
            ...payload,
            accessToken: payload.token,
            isLoading: false,
            isLoggedIn: true,
          };
          return newState;
        },
      )
      .addMatcher(
        ProfileApi.endpoints.GetProfile.matchFulfilled,
        (state, { payload }) => {
          return { ...state, user: payload };
        },
      )
      .addMatcher(
        ProfileApi.endpoints.UpdateProfile.matchFulfilled,
        (state, { payload }) => {
          return { ...state, user: payload.updateUser.user };
        },
      );
  },
});

export const { logout, setLocation, setDrawer, setProfile } =
  AuthReducer.actions;

const persistConfig = {
  keyPrefix: 'loyaltydish-customer-ui.',
  key: 'user',
  version: 1,
  storage,
};
export default persistReducer(persistConfig, AuthReducer.reducer);
