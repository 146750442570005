import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Chip, Divider, Pagination } from '@mui/material';

import Header from '../components/Header';
import SpinLoader from '../components/SpinLoader';
import { getDateString } from '../helpers/utils';
import { setOrder } from '../store/reducers/CartReducer';
import { orderApi, useGetOrdersQuery } from '../store/services/OrderApi';

const statusVariant = {
  CART: 'default',
  PLACED: 'info',
  ACCEPTED: 'secondary',
  CANCELED: 'error',
  DENIED: 'error',
  IN_PREPARATION: 'info',
  READY: 'warning',
  COMPLETED: 'success',
};

function Orders() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalOrders, setTotalOrders] = useState(0);
  const itemsPerPage = 10;

  const { data: orders, isLoading } = useGetOrdersQuery({
    first: itemsPerPage,
    offset: (currentPage - 1) * itemsPerPage,
  });

  const ordersData = orders ? orders.slice(0, -1) : []

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(()=>{
    if(!isLoading) {
      setTotalOrders(orders[orders.length - 1])
    }
  }, [isLoading])

  const onReOrder = async (id) => {
    const getOrder = orderApi.endpoints.GetOrder;
    const { data: payload } = await dispatch(getOrder.initiate({ id }));
    dispatch(setOrder(payload));
    navigate(`/cart`);
  };

  const Dot = () => <div className="dot"></div>;

  const renderItem = (item) => {
    const { store, orderId, orderStatus, createdAt, totalAmount, items } = item;

    return (
      <>
        <div className="order-item">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className="order-id">{orderId}</div>
            <div className="status">
              <Chip
                color={statusVariant[orderStatus]}
                size="small"
                label={orderStatus}
              />
            </div>
          </div>

          <div
            onClick={() => navigate('/order/' + orderId)}
            className="caption strong underline"
          >
            View Receipt
          </div>

          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <div className="caption">{store?.name}</div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
            <div className="caption">{getDateString(new Date(createdAt))}</div>
            <Dot />
            <div className="caption">{items.edges.length} item</div>
            <Dot />
            <div className="caption">$ {totalAmount}</div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10,
              marginTop: 10,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => onReOrder(orderId)}
            >
              Reorder
            </Button>
            {orderStatus != 'COMPLETED' && orderStatus != 'CANCELED' && (
              <Button
                variant="outlined"
                onClick={() => navigate('/order/' + orderId + '/track')}
              >
                Track
              </Button>
            )}
          </div>
        </div>
        <Divider />
      </>
    );
  };

  return (
    <>
      <Header title="Order History" withDrawer withCartBtn />
      <div style={{ maxWidth: '800px', margin: 'auto' }}>
        {isLoading ? (
          <SpinLoader />
        ) : ordersData && ordersData.length > 0 ? (
          <div className="order-container">
            <p className="title">Today</p>
            <div className="order-list-container">
              {ordersData.map((item) => renderItem(item))}
            </div>
            <Pagination
              count={Math.ceil(totalOrders / itemsPerPage)}
              // count={10}
              page={currentPage}
              onChange={handlePageChange}
              color="secondary"
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
              }}
            />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: '50vh',
            }}
          >
            <div style={{ width: '70%', textAlign: 'center' }}>
              Looks like you haven't placed any orders yet. Don't worry, we're
              eagerly waiting for you to make your first order!
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Orders;
